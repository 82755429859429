<template>
  <div class="white_box" style="background: #fff">
    <div class="container-white_box">
      <div class="white_box-top">
        <h3 class="title-box">
          인구 통계
          <span @click="titleInfo = !titleInfo">
            <img src="@/assets/notice-icon.svg" alt="인포 아이콘"
          /></span>
        </h3>
        <div class="title-box_info" v-if="titleInfo">
          <p>최근 한달 기준의 성별, 연령별 비율입니다.</p>
          <span @click="titleInfo = !titleInfo">
            <img src="@/assets/home_remove_all_m.svg" alt="닫기 아이콘" />
          </span>
        </div>
      </div>

      <div
        class="empty_component"
        v-if="dataActive === false"
        style="color: #d6dce2"
      >
        <div>
          <div class="announce_icon_fill"></div>
          <div class="empty_text">데이터가 존재하지 않습니다.</div>
        </div>
      </div>

      <div class="table_population" v-if="dataActive === true">
        <div style="position: relative">
          <div class="table-wrap">
            <div v-if="loading" class="loading">
              <LoadingView></LoadingView>
            </div>
            <table class="table_population-sex">
              <thead>
                <tr>
                  <th colspan="3">성별</th>
                </tr>
                <tr>
                  <th v-for="item in genderHeader" :key="item">{{ item }}</th>
                </tr>
              </thead>
              <tbody v-if="!errBox">
                <!-- 성별 검색량 male,female  -->
                <tr v-for="(item, index) in genderTableItems" :key="index">
                  <th>{{ item.keyword }}</th>

                  <td
                    v-if="item.max !== 0"
                    :class="{
                      value_min: item.male === item.min,
                      value_max: item.male === item.max,
                    }"
                  >
                    {{ item.male }} %
                  </td>
                  <td v-else>-</td>
                  <td
                    v-if="item.max !== 0"
                    :class="{
                      value_min: item.female === item.min,
                      value_max: item.female === item.max,
                    }"
                  >
                    {{ item.female }} %
                  </td>
                  <td v-else>-</td>
                </tr>
              </tbody>
            </table>
            <div class="table_bg"></div>
          </div>

          <div v-if="errBox">
            <article class="no_data">
              <div class="announce_icon_fill no_data_table_img"></div>
              <div class="empty_text no_data_table">
                데이터 공급처 장애로 서비스가 지연되고 있습니다.
              </div>
            </article>
          </div>
        </div>
        <div style="position: relative">
          <div v-if="loading" class="loading">
            <LoadingView></LoadingView>
          </div>
          <div class="table-wrap">
            <table class="table_population-total">
              <thead>
                <tr>
                  <th class="table_name_keyword"></th>
                  <th colspan="5">연령</th>
                </tr>
                <tr>
                  <th class="table_bg_th">키워드</th>
                  <th v-for="item in ageHeader" :key="item">{{ item }}</th>
                </tr>
              </thead>
              <tbody v-if="!errBox">
                <!-- 연령 추후 고도화 예정 -->
                <tr v-for="(item, index) in ageTableItems" :key="index">
                  <!-- 연령별 검색량  -->
                  <th>{{ item.keyword }}</th>
                  <!-- 최소 -->
                  <td
                    v-if="item.max !== 0"
                    :class="{
                      value_min: item[10] == item.min,
                      value_max: item[10] == item.max,
                    }"
                  >
                    {{ item[10] }} %
                  </td>
                  <td v-else>-</td>
                  <td
                    v-if="item.max !== 0"
                    :class="{
                      value_min: item[20] == item.min,
                      value_max: item[20] == item.max,
                    }"
                  >
                    {{ item[20] }} %
                  </td>
                  <td v-else>-</td>
                  <td
                    v-if="item.max !== 0"
                    :class="{
                      value_min: item[30] == item.min,
                      value_max: item[30] == item.max,
                    }"
                  >
                    {{ item[30] }} %
                  </td>
                  <td v-else>-</td>
                  <td
                    v-if="item.max !== 0"
                    :class="{
                      value_min: item[40] == item.min,
                      value_max: item[40] == item.max,
                    }"
                  >
                    {{ item[40] }} %
                  </td>
                  <td v-else>-</td>
                  <td
                    v-if="item.max !== 0"
                    :class="{
                      value_min: item[50] == item.min,
                      value_max: item[50] == item.max,
                    }"
                  >
                    {{ item[50] }} %
                  </td>
                  <td v-else>-</td>
                  <!-- <td
                    v-if="item.max !== 0"
                    :class="{
                      value_min: item[60] == item.min,
                      value_max: item[60] == item.max,
                    }"
                  >
                    {{ item[60] }} %
                  </td>
                  <td v-else>-</td> -->
                </tr>
              </tbody>
            </table>
          </div>

          <!-- nodata -->

          <div v-if="errBox">
            <article class="no_data">
              <div class="announce_icon_fill no_data_table_img"></div>
              <div class="empty_text no_data_table">
                데이터 공급처 장애로 서비스가 지연되고 있습니다.
              </div>
            </article>
          </div>

          <div class="table_bg"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapActions } from "vuex";
  import { axiosService } from "@/api/index";
  import LoadingView from "@/components/loading/loadingView.vue";

  export default {
    name: "PopulationView",
    props: ["data", "type", "keyArr"],
    components: { LoadingView },
    data() {
      return {
        ageHeader: ["10대", "20대", "30대", "40대", "50대"],
        genderHeader: ["키워드", "남성", "여성"],
        ageTableItems: [],
        genderTableItems: [],
        keyWordItems: [],
        loading: false,
        dataActive: false,
        windowWidth: window.innerWidth,
        txt: "",
        interval: "daily",
        errBox: false,

        titleInfo: false,
      };
    },
    watch: {
      data(v) {
        if (!v) {
          this.loading = false;
          this.dataActive = false;
        } else {
          this.dataActive = true;
          if (this.type !== "demo") {
            this.getData();
          } else {
            this.demoData();
          }
        }
      },
    },

    mounted() {
      this.$nextTick(() => {
        window.addEventListener("resize", this.onResize);
      });
    },
    methods: {
      ...mapActions({
        getTrendData: "keyword/getTrendData",
      }),
      onResize() {
        this.windowWidth = window.innerWidth;
      },
      demoData() {
        this.interval = this.$route.query.interval
          ? this.$route.query.interval
          : this.interval;
        let params = {
          interval: this.interval,
        };
        if (this.$route.query.demoKey) {
          params.category = this.$route.query.demoKey;
        }
        axiosService(`v2/demo2/keyword/trend`, { params })
          .then((res) => {
            this.ageTableItems = res.data.map((d) => ({
              ...d.search.age,
              keyword: d.keyword,
            }));
            this.genderTableItems = res.data.map((d) => ({
              ...d.search.gender,
              keyword: d.keyword,
            }));
            this.loading = false;
            this.errBox = false;
          })
          .catch(() => {
            this.errBox = true;
            this.loading = false;
          });
      },
      async getData() {
        this.loading = true;
        this.getTrendData(this.keyArr)
          .then((res) => {
            this.ageTableItems = res.data.map((d) => ({
              ...d.search.age,
              keyword: d.keyword,
            }));
            this.genderTableItems = res.data.map((d) => ({
              ...d.search.gender,
              keyword: d.keyword,
            }));
            this.loading = false;
            this.errBox = false;
          })
          .catch(() => {
            this.errBox = true;
            this.loading = false;
          });
      },
      init() {
        if (
          this.data.data !== undefined &&
          this.$route.query.keywords !== undefined
        ) {
          this.dataActive = true;
          if (this.type !== "demo") {
            this.getData();
          } else {
            this.demoData();
          }
        } else {
          this.dataActive = false;
        }
      },
    },
  };
</script>
<style lang="css" scoped>
  .loading {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
  }
  .widthMax_else {
    display: block;
  }

  .widthMax {
    display: none;
  }

  .table-wrap thead tr:last-child th {
    padding: 22px 0;
  }

  .table-wrap thead tr th {
    width: auto;
  }

  .table_name_keyword {
    width: 14% !important;
  }

  article.no_data {
    padding: 46px 0;
  }
  .no_data_table_icon {
    width: 37px;
    height: 37px;
    margin-bottom: 5px;
  }
  .no_data_table {
    font-size: 16px;
    text-align: center;
  }

  /* 인구통계 커스텀 */
  .title-box span img {
    display: inline-block;
    cursor: pointer;
  }
  .title-box_info {
    height: 52px;
    position: absolute;
    z-index: 4;
    top: 50%;
    transform: translateY(-50%);
    left: 110px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 20px;
    border-radius: 7px;
    box-shadow: 0 20px 20px 0 #0041a208;
    border: solid 1px #f1f4f8;
    background-color: #fff;

    box-sizing: border-box;
  }
  .title-box span {
    display: inline-block;
    position: relative;
    cursor: pointer;
  }
  .title-box_info p {
    margin-bottom: 0;
    margin-right: 20px;
    font-size: 14px;
  }
  .white_box-top {
    position: relative;
  }
  @media screen and (max-width: 1440px) {
    .widthMax {
      display: block;
    }

    .widthMax_else {
      display: none;
      widows: 10;
    }
  }

  @media screen and (max-width: 1023px) {
    .table_population > div:first-child {
      width: 100%;
      /* min-width: 400px; */
      margin-right: 20px;
    }

    article.no_data {
      padding: 46px 0;
    }
    .no_data_table_icon {
      width: 37px;
      height: 37px;
      margin-bottom: 5px;
    }
    .no_data_table {
      font-size: 14px;
      text-align: center;
    }

    .table_population > div:last-child {
      width: 100%;
      /* min-width: 1180px; */
    }
  }

  @media screen and (max-width: 800px) {
    .table_name_keyword {
      width: 120px !important;
    }
    .title-box_info {
      left: 0;
      top: 35px;
      transform: unset;
      height: auto;
      align-items: flex-start !important;
    }
  }
</style>
