<template>
  <div class="white_box" style="background: #fff">
    <div class="container-white_box">
      <div class="white_box-top">
        <h3 class="title-box">차트</h3>
      </div>

      <div
        class="empty_component"
        v-if="dataActive === false"
        style="color: #d6dce2"
      >
        <div>
          <div class="announce_icon_fill"></div>
          <div class="empty_text">{{ infoText }}</div>
        </div>
      </div>

      <div v-else style="hegith: 300px">
        <div v-if="loading" style="height: 300px">
          <LoadingView></LoadingView>
        </div>
        <div
          v-else
          class="chart"
          style="height: 300px"
          :style="`height:${chartHeight}px`"
        >
          <div style="height: 100%" id="chart" ref="chartDom"></div>
        </div>
      </div>

      <!-- <a-spin :spinning="loading" v-else>
        <div class="chart" :style="`height:${chartHeight}px`">
          <div id="chart" ref="chartDom"></div>
        </div>
      </a-spin> -->
    </div>
  </div>
</template>
<script>
  import * as echarts from "echarts";
  import moment from "moment";
  import LoadingView from "@/components/loading/loadingView.vue";
  export default {
    props: ["data", "loading", "type"],
    components: {
      LoadingView,
    },
    data() {
      return {
        series: [],
        xAxisClone: {
          axisLabel: {
            formatter: function (value) {
              return value.substr(5, 10);
            },
            textStyle: {
              color: function (value) {
                if (moment(value).day() === 6) {
                  return "#3944ff";
                }
                if (moment(value).day() === 0) {
                  return "red";
                } else {
                  return "#111";
                }
              },
              fontSize: 12,
            },
          },
          type: "category",
          boundaryGap: false, //날짜데이터
          data: [],
          axisPointer: {
            snap: true,
            // type: "shadow",
          },
        },
        xAxis: {
          axisLabel: {},
          type: "category",
          boundaryGap: false, //날짜데이터
          data: [],
          axisPointer: {
            snap: true,
            // type: "shadow",
          },
        },
        yearXAxis: {
          axisLabel: {
            textStyle: {
              color: function (value) {
                if (moment("20" + value + "-01T01:01:01").month() === 0) {
                  return "#3944ff";
                } else {
                  return "#111";
                }
              },
              fontSize: 12,
            },
          },
          type: "category",
          boundaryGap: false, //날짜데이터
          data: [],
          axisPointer: {
            snap: true,
            // type: "shadow",
          },
        },

        legend: {
          // type: "scroll",
          data: [],
        },

        keyWordItems: [],
        dataActive: false,

        chartHeight: 0,
        windowWidth: 0,
        markLineData: [],
        lineWidth: 30,

        legendActive: {},

        opactiy: 0.2,

        //state
        interval: "daily",
        seven: false,

        dateType: "1",

        infoText: "",
      };
    },
    watch: {
      async data(v) {
        if (!v || v?.data?.length === 0) {
          this.dataActive = false;
          this.chartHeight = 0;
        } else {
          await this.init();
          this.dataActive = true;
          this.chartHeight = 300;
          if (this.type !== "demo") {
            setTimeout(() => {
              this.getData();
            }, 300);
          } else {
            setTimeout(() => {
              this.getData();
            }, 300);
          }
        }
      },

      loading(v) {
        if (v === false && this.data !== undefined) {
          if (this.data.data.length === 0) {
            this.dataActive = false;
          }
        }
      },
    },
    async mounted() {
      if (this.$route.name === "HomeView") {
        this.infoText = "데이터가 존재하지 않습니다.";
      } else {
        this.infoText = "키워드를 선택해 주세요.";
      }
      if (this.data !== undefined || this.data?.data?.length !== 0) {
        this.dataActive = true;
        await this.init();
        if (this.type !== "demo") {
          this.getData();
        } else {
          this.getData();
        }
      } else {
        this.dataActive = false;
      }
      this.windowWidth = window.innerWidth;
    },

    methods: {
      setMarkLineData(index, color) {
        this.markLineData.push({
          xAxis: index,
          lineStyle: {
            color: color,
            opacity: 0.2,
          },
        });
      },

      resetChartField() {
        this.series = [];
        this.legend.data = [];
        this.xAxis.data = [
          {
            type: "category",
            axisTick: { show: false },
          },
        ];
        this.xAxisClone.data = [
          {
            type: "category",
            axisTick: { show: false },
          },
        ];
        this.yearXAxis.data = [
          {
            type: "category",
            axisTick: { show: false },
          },
        ];
      },

      async init() {
        this.interval = this.$route.query.interval
          ? this.$route.query.interval
          : this.interval;

        this.dateType = this.$route.query.dateType
          ? this.$route.query.dateType
          : this.dateType;

        if (this.$route.query.seven === "false" || !this.$route.query.seven) {
          this.seven = false;
        } else {
          this.seven = true;
        }
      },

      async getData() {
        this.markLineData = [];
        this.resetChartField();

        let date = [];

        if (this.data.data !== undefined) {
          this.data?.data[0]?.search.map((item, index) => {
            if (this.seven === true) {
              let text = item.date;
              date.push(
                text === "Sun"
                  ? "일"
                  : text === "Mon"
                  ? "월"
                  : text === "Tue"
                  ? "화"
                  : text === "Wed"
                  ? "수"
                  : text === "Thu"
                  ? "목"
                  : text === "Fri"
                  ? "금"
                  : "토"
              );
            } else {
              date.push(
                item.date.length === 10
                  ? moment(item.date).format("YYYY-MM-DD")
                  : item.date
              );
            }
            if (this.interval === "daily") {
              if (moment(item.date).day() === 6) {
                this.setMarkLineData(index, "rgba(255,197,197)");
              }
              if (moment(item.date).day() === 0) {
                this.setMarkLineData(index, "rgba(187,222,251)");
              }
            } else if (this.interval === "monthly") {
              if (moment("20" + item.date + "-01T01:01:01").month() === 0) {
                this.setMarkLineData(index, "rgba(187,222,251)");
              }
            }
          });

          //가짜배열
          let keyItem = [];
          let legendName = [];

          if (this.data?.data[0]?.search.length >= 5) {
            this.opactiy = 0.15;
          } else if (this.data?.data[0]?.search.length >= 10) {
            this.opactiy = 0.1;
          }

          for (let i = 0; this.data.data.length > i; i++) {
            let data = [];
            this.data.data[i].search.map((item) => {
              data.push(item["amount"]);
            });
            // if (this.dateType === "3" && this.interval === "daily") {
            //   this.lineWidth = 10;
            // } else if (this.dateType === "year" && this.interval === "daily") {
            //   this.lineWidth = 3;
            // }

            // if (this.windowWidth < 400) {
            //   this.lineWidth = 3;
            // } else if (400 < this.windowWidth && this.windowWidth < 800) {
            //   this.lineWidth = 15;
            // }

            legendName.push(this.data.data[i].keyword);

            if (this.seven === true) {
              keyItem.push({
                name: this.data.data[i].keyword,
                type: "bar",
                index: i,
                data: data,
                barGap: 0,
                barWidth: "12%",
                emphasis: {
                  focus: "series",
                },
              });
              this.xAxis.boundaryGap = true;
              this.xAxisClone.boundaryGap = true;
              this.yearXAxis.boundaryGap = true;
            } else {
              keyItem.push({
                name: this.data.data[i].keyword,
                type: "line",
                smooth: true,
                index: i,
                data: data,
                connectNulls: false,
                // markLine: {
                //   symbol: ["none", "none"],
                //   label: { show: false },
                //   data: this.markLineData,
                //   lineStyle: {
                //     width: this.lineWidth,
                //     type: "solid",
                //   },
                // },
              });
            }
          }
          this.xAxis.data = date;
          this.xAxisClone.data = date;
          this.yearXAxis.data = date;

          this.legend.data = legendName;

          this.series = keyItem;
          if (this.$refs.chartDom) {
            await this.setChart();
          }
        }
      },
      async setChart() {
        this.$nextTick(() => {
          const chartDom = this.$refs.chartDom;

          if (chartDom.clientHeight === 0) chartDom.style.height = "300px";

          let myChart = echarts.init(chartDom);
          if (myChart !== null && myChart !== undefined) {
            myChart.dispose();
          }

          myChart = echarts.init(chartDom);

          let option;

          option = {
            tooltip: {
              trigger: "axis",
              order: "valueDesc",
            },
            legend: this.legend,
            grid: {
              left: "3%",
              right: "6%",
              bottom: "3%",
              containLabel: true,
            },
            toolbox: {
              feature: {},
            },
            yAxis: {
              type: "value",
            },
            series: this.series,
          };
          if (
            this.interval === "daily" &&
            this.dateType === "1" &&
            this.seven === false
          ) {
            option.xAxis = this.xAxisClone;
          } else if (this.interval === "monthly" && this.dateType === "year") {
            option.xAxis = this.yearXAxis;
          } else if (
            this.interval === "monthly" &&
            this.dateType === "custom"
          ) {
            option.xAxis = this.yearXAxis;
          } else {
            option.xAxis = this.xAxis;
          }
          option && myChart.setOption(option);

          window.onresize = function () {
            myChart.resize();
          };
        });
      },
    },
  };
</script>
<style lang="css" scoped>
  #chart {
    width: 100%;
    height: 100%;
  }
  .ant-spin-nested-loading {
    height: 300px;
  }
</style>
