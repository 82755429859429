import axios from "axios";

const authPlugin = (store) => {
  store.subscribe((mutation) => {
    switch (mutation.type) {
      case "account/setToken":
        if (mutation.payload) {
          axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${mutation.payload}`;
          localStorage.setItem("t_access_token", mutation.payload);
        } else {
          localStorage.removeItem("t_access_token", null);
        }
        break;
    }
  });
};

export default authPlugin;
