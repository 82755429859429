import { render, staticRenderFns } from "./CompetitionView.vue?vue&type=template&id=4416b77a&scoped=true&"
import script from "./CompetitionView.vue?vue&type=script&lang=js&"
export * from "./CompetitionView.vue?vue&type=script&lang=js&"
import style0 from "./CompetitionView.vue?vue&type=style&index=0&id=4416b77a&prod&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4416b77a",
  null
  
)

export default component.exports