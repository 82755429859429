<template>
  <v-app id="app">
    <ConfirmModal
      v-if="demoView"
      @modalClose="modalClose"
      :active="modal"
      :checkType="'info'"
      :title="'멤버십 전용 서비스'"
      :text="'팅서치 멤버십으로 더 \n 많은 인사이트를 확인하세요'"
      :button="'시작하기'"
      :type="'demo'"
    />

    <!-- 체험하기 안내문구  -->

    <v-alert
      v-if="demoView"
      dense
      type="info"
      style="
        position: fixed;
        bottom: 0px;
        z-index: 99;
        margin-bottom: 0;
        width: 100%;
        height: 40px;
        border-radius: 0;
        background-color: rgb(0, 0, 0, 0.7) !important;
      "
    >
      <p style="color: white">
        본 화면은 <strong style="color: white">체험하기</strong> 화면입니다
      </p>
    </v-alert>

    <!--  본문 -->

    <div class="wrap" :style="{ paddinBotom: '100px' }">
      <Floating />
      <AlertBarView />

      <!-- main 공통버전 -->
      <NavWrap @logout="logout" v-if="localToken" />
      <HeaderSearchBar @logout="logout" v-if="localToken" />

      <!-- demo 공통버전 -->
      <DemoNavWrap @setMsg="setMsg" v-if="demoView" />
      <DemoHeader @setMsg="setMsg" v-if="demoView" />

      <router-view @setMsg="setMsg" :key="$route.fullPath" />
    </div>
    <FooterView @logout="logout" v-if="localToken" />
  </v-app>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";
  import NavWrap from "@/components/common/NavWrap.vue";
  import HeaderSearchBar from "@/components/common/HeaderSearchBar.vue";
  import AlertBarView from "@/components/common/AlertBarView.vue";
  import FooterView from "@/components/common/FooterView.vue";
  import ConfirmModal from "@/components/modal/ConfirmModal.vue";

  import AppUtility from "@/utility/app";

  //floating
  import DemoNavWrap from "@/views/demo/common/DemoNavWrap.vue";
  import DemoHeader from "@/views/demo/common/DemoHeader.vue";
  import Floating from "@/components/common/Floating.vue";

  export default {
    name: "App",
    components: {
      NavWrap,
      HeaderSearchBar,
      AlertBarView,
      FooterView,
      Floating,
      // demo
      DemoNavWrap,
      DemoHeader,
      ConfirmModal,
    },
    computed: {
      ...mapGetters({
        token: "account/authToken",
        msg: "controller/activeMessage",
        subscript: "account/authSubscription",
        me: "account/authUser",
      }),
    },
    data() {
      return {
        localToken: false,
        demoView: false,
        modal: false,
      };
    },
    mounted() {
      this.checkUser();

      //구독이 없는 경우 + 해당 페이지가 아닌경우
      if (
        this.subscript === false &&
        location.pathname !== "/payment/fail" &&
        location.pathname !== "/payment/success" &&
        location.pathname !== "/payment/cancel" &&
        // location.pathname !== "/keyword/analysis/guide" &&
        location.pathname !== "/memeber/info" &&
        location.pathname !== "/" &&
        location.pathname !== "/demo" &&
        location.pathname !== "/demo/keyword/manager" &&
        location.pathname !== "/demo/group/manager" &&
        location.pathname !== "/demo/news/feeds" &&
        location.pathname !== "/demo/news/save" &&
        location.pathname !== "/m" &&
        location.pathname !== "/email-check" &&
        location.pathname !== "/mypage" &&
        location.pathname !== "/secession"
      ) {
        // this.master = this.me.isMaster;

        this.$store.commit("controller/setMsg", "구독 후 이용이 가능합니다");
        this.$router.replace({ name: "MemberInfo" }).catch(() => {});
      }
    },
    updated() {
      this.checkUser();
    },
    watch: {
      $route(to, from) {
        //체험하기 안내문구 출력
        if (this.$isDemo) {
          window.scroll(0, 0);
          return;
        }

        if (
          // to.name !== "GuideHomeView" &&
          this.subscript === false &&
          to.name !== "FailView" &&
          to.name !== "SuccessView" &&
          to.name !== "CancelView" &&
          to.name !== "MemberInfo" &&
          location.pathname !== "/demo" &&
          location.pathname !== "/demo/keyword/manager" &&
          location.pathname !== "/demo/group/manager" &&
          location.pathname !== "/demo/news/feeds" &&
          location.pathname !== "/demo/news/save" &&
          location.pathname !== "/" &&
          location.pathname !== "/m" &&
          to.name !== "MypageView" &&
          to.name !== "EmailIndex" &&
          to.name !== "SecessionIndex"
        ) {
          this.$store.commit("controller/setMsg", "구독 후 이용이 가능합니다");
          this.$router.push({ name: "MemberInfo" }).catch(() => {});
        } else if (from.name !== null) {
          if (to.name !== from.name) {
            this.$store.commit("keyword/setManagerKeyword", []);
            this.$store.commit("keyword/setPage", 1);
            if (to.name !== "SNS") {
              //라우트를 쿼리만
              this.$router
                .push({
                  name: to.name,
                  query: {
                    keywords: this.$store.state.keyword.keywords,
                    startDate: this.$store.state.keyword.startDate,
                    endDate: this.$store.state.keyword.endDate,
                    interval: this.$store.state.keyword.interval,
                    dateType: this.$store.state.keyword.dateType,
                    seven: this.$store.state.keyword.by_seven,
                  },
                })
                .catch(() => {});
            }
          }
        } else {
          let arr = Object.assign({}, this.$route.query);
          this.$router
            .push({
              name: to.name,
              query: { keywords: this.$store.state.keyword.keywords, ...arr },
            })
            .catch(() => {});
        }

        window.scroll(0, 0);
      },
    },
    methods: {
      ...mapActions({
        logoutAction: "account/logout",
        getStatsData: "keyword/getStatsData",
      }),

      setMsg() {
        this.modal = true;
      },
      async modalClose(type) {
        if (type === "bg") {
          this.modal = false;
        } else {
          let active = await AppUtility("login");

          if (!active) {
            location.href = "/login";
          }
        }
      },
      checkUser() {
        //로그인상태 확인(네비바 조절)
        if (
          localStorage.getItem("t_access_token") &&
          this.$route.name !== "HomeWeb" &&
          this.$route.name !== "HomeMobile" &&
          this.$route.name !== "PasswordView" &&
          this.$route.name !== "CancelView" &&
          this.$route.name !== "SuccessView" &&
          this.$route.name !== "FailView" &&
          this.$route.name !== "RegisterIndex" &&
          this.$route.name !== "EmailIndex" &&
          this.$route.name !== "DemoView" &&
          this.$route.name !== "DemoKeywordManage" &&
          this.$route.name !== "DemoGroupManage" &&
          this.$route.name !== "DemoNewsFeeds" &&
          this.$route.name !== "DemoNewsSave"
        ) {
          this.localToken = true;
        } else {
          this.localToken = false;
          this.logout();
        }

        if (this.$route.path.slice(0, 5) === "/demo") {
          this.demoView = true;
        } else {
          this.demoView = false;
        }
      },
      logout() {
        // this.logoutAction().then(() => {
        //   this.$router
        //     .replace({
        //       path: "/login",
        //     })
        //     .catch(() => {});
        // });
      },
    },
  };
</script>

<style>
  .theme--light.v-application {
    background-color: transparent !important;
  }
  .v-application .header_gnb-text a {
    color: inherit;
  }
  body {
    cursor: unset;
    height: auto;
  }
  #searchBar {
    height: 42px;
  }
  #searchBar
    .theme--light.v-text-field
    > .v-input__control
    > .v-input__slot:before {
    border: none;
  }
  #searchBar
    .v-text-field.v-input--is-focused
    > .v-input__control
    > .v-input__slot:after {
    display: none;
  }
  .ant-calendar-picker-input.ant-input[disabled] {
    background-color: #f1f4f8 !important;
    color: #bac0c6;
    font-size: 14px;
  }
  .ant-calendar-picker-input.ant-input {
    padding: 0 20px;
  }
  .v-application--is-ltr #searchBar .v-text-field .v-label {
    color: #d6dce2 !important;
  }

  /* ant-design setting */

  .ant-calendar td {
    width: auto;
    min-width: none;
  }
  .ant-calendar-month .ant-calendar-month-header-wrap {
    height: 312px !important;
  }
  .ant-calendar-picker-input.ant-input {
    text-align: right;
  }
  .ant-calendar-picker-icon {
    left: 20px;
    top: 9px !important;
    margin: 0 !important;
    color: #111 !important;
    font-size: 14px;
    font-weight: normal;
  }
  .ant-calendar-picker-clear {
    top: 20px !important;
    left: 15px;
  }
  .v-snack__content {
    color: white !important;
  }

  #keywordManage
    .theme--light.v-text-field
    > .v-input__control
    > .v-input__slot:before,
  #keywordManage
    .v-text-field.v-input--is-focused
    > .v-input__control
    > .v-input__slot:after {
    border: none !important;
  }
  .ant-calendar-year-panel-last-decade-cell .ant-calendar-year-panel-year,
  .ant-calendar-year-panel-next-decade-cell .ant-calendar-year-panel-year {
    color: #188fffab !important;
  }

  .ant-calendar-year-panel-cell-disabled .ant-calendar-year-panel-year {
    color: #bac0c6 !important;
  }
  #not_style label {
    color: #111;
    font-weight: bold;
  }

  #event_flooting .v-overlay--active .v-overlay__scrim {
    opacity: 0.8 !important;
  }
  #event_flooting .theme--dark.v-overlay {
    z-index: 1001 !important;
  }
  #event_flooting .v-overlay__content {
    width: 100%;
  }

  .v-list-item .v-list-item__title,
  .v-list-item .v-list-item__subtitle {
    font-size: 14px;
  }

  .ant-modal-body {
    max-height: 82vh;
    overflow: auto;
  }

  .theme--light.v-chip:not(.v-chip--active) {
    background: transparent !important;
    border: solid 1px #eaeef3 !important;
    /* padding: 0px 16px;
    height: 42px; */
    box-sizing: border-box;
  }

  @media all and (max-width: 1440px) {
    .date_filter-btn.search_btn button.active {
      width: 100%;
    }
    .date_filter-btn:not(.search_btn) button {
      display: none;
    }
    .date_filter-btn:not(.search_btn) {
      margin-bottom: 0;
    }
    .date_filter-btn select {
      display: block;
    }
    .ant-modal-body {
      overflow: auto;
      height: 550px;
      max-height: 90% !important;
    }

    /*  */

    .ant-calendar-picker-clear {
      top: 27px !important;
    }
    .date_filter-date > span:nth-child(2n-1) .ant-calendar-picker-icon {
      display: block;
      top: 13px !important;
      width: 200px;
    }
    .date_filter-date > span:nth-child(2n) {
      display: none;
    }

    /*  */
    #not_style {
      justify-content: end;
      margin-right: 20px;
    }
    #not_style .v-messages {
      display: none;
    }
  }

  .v-input--selection-controls .v-input__slot > .v-label {
    font-size: 14px;
    /* color: #111; */
  }
  #feeds
    .theme--light.v-text-field
    > .v-input__control
    > .v-input__slot:before {
    display: none !important;
  }

  #newsSearchBar
    .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
    > .v-input__control
    > .v-input__slot {
    box-shadow: none;
    padding-left: 0;
  }
  #newsSearchBar
    .theme--light.v-text-field--solo
    > .v-input__control
    > .v-input__slot {
    background-color: transparent;
  }
  #newsSearchBar .v-list-item__title {
    font-size: 14px;
  }
  #newsSearchBar .mdi-close {
    font-size: 14px;
  }
  #newsSearchBar .theme--light.v-chip:not(.v-chip--active) {
    background: transparent;
    border: solid 1px #e4e9f0;
    font-size: 14px;
  }
  #newsSearchBar .v-text-field.v-text-field--solo .v-label {
    font-size: 14px;
    color: #d6dce2;
  }
  #newsSearchBar .v-text-field.v-text-field--solo .v-input__control {
    min-height: 42px;
  }
  #newsSearchBar .v-select__slot {
    bottom: 3px;
  }

  #searchBar .mdi-menu-down::before {
    display: none;
  }

  .v-autocomplete__content.v-menu__content {
    box-shadow: 0 40px 40px 0 rgba(0, 65, 162, 0.2) !important;
    border-radius: 8px !important;
    border: solid 1px #e4e9f0;
  }
  .v-application--is-ltr #searchBar .v-text-field .v-label {
    font-size: 14px !important;
  }

  .mdi-checkbox-marked::before {
    content: "";
    width: 26px;
    height: 26px;
    background-image: url(@/assets/img/check_icon.svg);
    background-size: "50%";
    background-position: center;
    background-repeat: no-repeat;
    background-color: #3943ff;
    border-radius: 50%;
    box-sizing: border-box;
  }
  .mdi-checkbox-blank-outline::before {
    content: "";
    width: 26px;
    height: 26px;
    background: #fff;
    border-radius: 50%;
    border: solid 1px #e4e9f0;
    box-sizing: border-box;
  }
  .theme--light.v-list-item--active::before {
    background-color: #f7fbff !important;
    opacity: 1 !important;
  }
  .theme--light.v-list-item--active .v-list-item__title {
    z-index: 1;
  }
  .theme--light.v-list-item:hover::before,
  .theme--light.v-list-item:focus::before {
    background-color: #f7fbff !important;
    opacity: 1 !important;
  }
  .v-list-item--link:before {
    background-color: unset !important;
  }
  .theme--light.v-list-item:hover .v-list-item__title {
    z-index: 1;
  }
  .theme--light.v-list-item .v-list-item__title {
    z-index: 1;
  }
  .v-list-item {
    height: 50px;
  }
  .theme--light.v-chip--active::before {
    background-color: #f7f9fb;
    opacity: 1 !important;
  }
  .theme--light.v-chip--active span {
    z-index: 1;
  }
  @media screen and (max-width: 1024px) {
    #newsSearchBar .v-autocomplete__content.v-menu__content {
      left: 40px !important;
    }
  }
</style>
