import moment from "moment";
import { axiosService } from "@/api/index";

export default {
  namespaced: true,

  state: {
    keywords: [],
    interval: "",
    startDate: "",
    endDate: "",
    dateType: "",
    data: [],
    query: {},
    mobileKeyword: "",
    footerActive: false,
    by_seven: "",
    managerArr: [],
    page: 1,
  },

  getters: {
    keywordState(state) {
      return state.keywords;
    },
    mobileKeywordState(state) {
      return state.mobileKeyword;
    },
    footerActiveState(state) {
      return state.footerActive;
    },
    getPageState(state) {
      return state.page;
    },
  },

  mutations: {
    setPage(state, payload) {
      state.page = payload;
    },
    setFooter(state, payload) {
      state.footerActive = payload;
    },
    setData(state, payload) {
      state.data = payload;
    },
    setMobileKeyword(state, payload) {
      state.mobileKeyword = payload;
    },

    setDateType(state, payload) {
      state.dateType = payload;
    },
    setInterval(state, payload) {
      state.interval = payload;
    },

    setSeven(state, payload) {
      state.by_seven = payload;
    },

    setParams(state, payload) {
      let format = "YYYY-MM-DD";
      if (payload.interval === "weekly") {
        format = "YYYY-MM";
      } else if (payload.interval === "monthly") {
        format = "YYYY";
      }
      state.interval = payload.interval;
      state.startDate =
        typeof payload.startDate === "string"
          ? payload.startDate
          : moment(payload.startDate).format(format);
      state.endDate =
        typeof payload.endDate === "string"
          ? payload.endDate
          : moment(payload.endDate).format(format);
      state.dateType = payload.dateType;
      state.by_seven = payload.seven;
    },

    setKeyword(state, payload) {
      state.keywords = [];
      state.keywords = payload;
    },
    setManagerKeyword(state, payload) {
      state.managerArr = [...payload];
    },
  },
  actions: {
    async getStatsData({ state }) {
      //string으로 들어오는 keywords를 바꿔줌
      let key = [];

      if (typeof state.keywords === "string") {
        key = Array(state.keywords);
      } else {
        key = state.keywords;
      }

      let params = {
        keywords: key,
        interval: state.interval,
        start_date: state.startDate,
        end_date: state.endDate,
        dateType: state.dateType,
        by_seven: state.by_seven === false ? "N" : "Y",
      };

      if (key.length > 0) {
        return await axiosService.get("/v2/search/keyword/stats", {
          params,
        });
      }
    },
    async getTrendData({ state }, key) {
      // let key = [];

      // if (typeof state.keywords === "string") {
      //   key = Array(state.keywords);
      // } else {
      //   key = state.keywords;
      // }
      let params = {
        keywords: key,
        interval: state.interval,
        start_date: state.startDate,
        end_date: state.endDate,
      };
      return await axiosService.get("/v2/search/keyword/trend", { params });
    },
    async getCompetitionData({ state }) {
      let key = [];

      if (typeof state.keywords === "string") {
        key = Array(state.keywords);
      } else {
        key = state.keywords;
      }

      return await axiosService.get("/v2/search/keyword/competition", {
        params: {
          keywords: key,
          interval: state.interval,
          start_date: state.startDate,
          end_date: state.endDate,
        },
      });
    },

    async getKeywordData({ state }) {
      let items = await axiosService.get("/v2/keyword", {
        params: {
          interval: state.interval,
          startDate: state.startDate,
          endDate: state.endDate,
        },
      });
      return items;
    },
  },
};
