<template>
  <!-- table: 경쟁력 -->
  <div class="white_box" style="background: #fff">
    <div class="container-white_box">
      <div class="white_box-top">
        <h3 class="title-box">경쟁력</h3>
      </div>

      <div
        class="empty_component"
        v-if="dataActive === false"
        style="color: #d6dce2"
      >
        <div>
          <div class="announce_icon_fill"></div>
          <div class="empty_text">데이터가 존재하지 않습니다.</div>
        </div>
      </div>

      <!-- <a-spin :spinning="loading" v-if="dataActive === true"> -->
      <div v-if="dataActive === true" style="position: relative">
        <div v-if="loading" class="loading">
          <LoadingView></LoadingView>
        </div>
        <div class="another-table-wrap" style="">
          <div class="table-wrap">
            <table class="table_compete">
              <thead>
                <tr>
                  <th class="table_border"></th>
                  <th colspan="3">검색량</th>
                  <!-- <th colspan="3">월간 콘텐츠발행량</th>
                <th colspan="3">누적 콘텐츠발행량</th> -->
                </tr>
                <tr>
                  <th class="table_bg_th">키워드</th>
                  <th>모바일</th>
                  <th>PC</th>
                  <th>합계</th>
                  <!-- <th>블로그</th>
                <th>카페</th>
                <th>뉴스</th>
                <th>블로그</th>
                <th>카페</th>
                <th>뉴스</th> -->
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in keyWordItems" :key="index">
                  <th>{{ item.keyword }}</th>
                  <!-- 검색량 -->
                  <td>{{ item.search_mobile.toLocaleString() }}</td>
                  <td>{{ item.search_pc.toLocaleString() }}</td>
                  <td>{{ item.search_total.toLocaleString() }}</td>
                </tr>
              </tbody>
            </table>
            <div class="table_bg"></div>
          </div>
          <div class="table-wrap month">
            <table class="table_compete">
              <thead>
                <tr>
                  <th class="table_border"></th>
                  <!-- <th colspan="3">검색량</th> -->
                  <th colspan="3">월간 콘텐츠발행량</th>
                  <!-- <th colspan="3">누적 콘텐츠발행량</th> -->
                </tr>
                <tr>
                  <th class="table_bg_th">키워드</th>
                  <!-- <th>모바일</th>
                <th>PC</th>
                <th>합계</th> -->
                  <th>블로그</th>
                  <th>카페</th>
                  <th>뉴스</th>
                  <!-- <th>블로그</th>
                <th>카페</th>
                <th>뉴스</th> -->
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in keyWordItems" :key="index">
                  <th>{{ item.keyword }}</th>
                  <!-- 월간 콘텐츠 발행량 -->
                  <td>{{ item.blog_month?.toLocaleString() }}</td>
                  <td>{{ item.cafe_month?.toLocaleString() }}</td>
                  <td>{{ item.news_month?.toLocaleString() }}</td>
                </tr>
              </tbody>
            </table>
            <div class="table_bg"></div>
          </div>
          <div class="table-wrap">
            <table class="table_compete">
              <thead>
                <tr>
                  <th class="table_border"></th>
                  <!-- <th colspan="3">검색량</th>
                <th colspan="3">월간 콘텐츠발행량</th> -->
                  <th colspan="3">누적 콘텐츠발행량</th>
                </tr>
                <tr>
                  <th class="table_bg_th">키워드</th>
                  <!-- <th>모바일</th>
                <th>PC</th>
                <th>합계</th>
                <th>블로그</th>
                <th>카페</th>
                <th>뉴스</th> -->
                  <th>블로그</th>
                  <th>카페</th>
                  <th>뉴스</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in keyWordItems" :key="index">
                  <th>{{ item.keyword }}</th>
                  <!-- 누적 콘텐츠 발행량 -->
                  <td>{{ item.blog_total.toLocaleString() }}</td>
                  <td>{{ item.cafe_total.toLocaleString() }}</td>
                  <td>{{ item.news_total.toLocaleString() }}</td>
                </tr>
              </tbody>
            </table>
            <div class="table_bg"></div>
          </div>
        </div>
      </div>
      <!-- </a-spin> -->
    </div>
  </div>
</template>
<script>
  import { mapActions } from "vuex";
  import { axiosService } from "@/api/index";
  import LoadingView from "@/components/loading/loadingView.vue";

  export default {
    name: "CompetitionView",
    props: ["data", "type"],
    components: { LoadingView },
    data() {
      return {
        keyWordItems: [],
        dataActive: false,
        loading: false,
      };
    },
    watch: {
      data(v) {
        if (!v) {
          this.dataActive = false;
        } else {
          this.dataActive = true;
          if (this.type !== "demo") {
            this.getData();
          } else {
            this.demoData();
          }
        }
      },
    },
    mounted() {
      this.init();
    },
    methods: {
      ...mapActions({
        getCompetitionData: "keyword/getCompetitionData",
      }),

      // demo 데이터 설정
      demoData() {
        this.loading = true;

        let params = {
          interval: this.$route.query.interval,
        };
        if (this.$route.query.demoKey) {
          params.category = this.$route.query.demoKey;
        }

        axiosService
          .get(`v2/demo2/keyword/competition`, { params })
          .then((res) => {
            this.keyWordItems = res.data;
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      },
      async getData() {
        this.loading = true;
        this.getCompetitionData()
          .then((res) => {
            let data = res.data.sort(function (a, b) {
              return b.search_total - a.search_total;
            });
            this.keyWordItems = data;
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      },
      init() {
        if (
          this.data.data !== undefined &&
          this.$route.query.keywords !== undefined
        ) {
          this.dataActive = true;
          if (this.type !== "demo") {
            this.getData();
          } else {
            this.demoData();
          }
        } else {
          this.dataActive = false;
        }
      },
    },
  };
</script>
<style lang="css" scoped>
  .loading {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
  }
  table {
    width: auto;
  }
  table thead th:first-child {
    width: 120px;
  }
  .white_box-top {
    flex-direction: unset;
  }

  .table-wrap {
    overflow: unset;
    margin-right: 20px;
  }
  .table-wrap:last-child {
    margin-right: 0;
  }

  .table-wrap thead tr:last-child th {
    padding: 22px 0;
  }
  .another-table-wrap {
    display: flex;
    overflow: auto;
  }
  .another-table-wrap table {
    width: calc(100% - 40px);
  }
  .another-table-wrap::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  .another-table-wrap::-webkit-scrollbar-thumb {
    background-color: #3943ff;
    border-radius: 2px;
    background-clip: padding-box;
  }
  .another-table-wrap::-webkit-scrollbar-track {
    background-color: #f7f9fb;
    border-radius: 2px;
  }

  @media screen and (max-width: 1440px) {
    .white_box-top {
      flex-direction: column;
    }
  }

  @media all and (max-width: 1023px) {
    /* table: population */
    .another-table-wrap {
      flex-direction: column;
    }
    .another-table-wrap .table-wrap {
      width: 100%;
      margin-right: 0;
    }
    .another-table-wrap .table-wrap:first-child {
      margin-right: 0;
      margin-bottom: 20px;
    }
    .another-table-wrap table {
      width: 100%;
    }
  }

  @media screen and (max-width: 600px) {
    thead tr:first-child th:first-of-type:not(.ant-calendar-column-header),
    .table_bg_th {
      width: 100px !important;
    }

    .another-table-wrap table {
      width: auto;
    }
    .table_compete tbody tr td {
      min-width: 90px;
      width: 100px;
    }
  }
</style>
