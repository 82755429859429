<template>
  <!-- 필터 기능을 데이터를 전달하는데 있습니다.
해당 필터 컴포넌트는 라우트를 시키고 상태를 전달하는 역할입니다. -->
  <div class="date_filter-wrap" style="justify-content: space-between">
    <div class="date_filter-option">
      <span class="title-date">기간</span>

      <select
        @change="setStore"
        v-model="interval"
        name="date-filter"
        id="dateFilterOption"
      >
        <option
          v-for="(item, index) in intervalItems"
          :key="index"
          :value="item.value"
        >
          {{ item.text }}
        </option>
      </select>

      <div class="date_filter-btn">
        <button
          v-for="(item, index) in dateTypeItems"
          :key="index"
          :value="item.value"
          :class="{
            active: item.active,
            disabled: interval === 'monthly' && item.disabled === true,
          }"
          @click="changeDateType(index)"
        >
          {{ item.text }}
        </button>
        <select
          v-if="interval === 'monthly'"
          @change="setDateType"
          v-model="dateType"
        >
          <option
            v-for="(item, index) in mobileDateType"
            :key="index"
            :value="item.value"
          >
            {{ item.text }}
          </option>
        </select>
        <select v-else @change="setDateType" v-model="dateType">
          <option
            v-for="(item, index) in dateTypeItems"
            :key="index"
            :value="item.value"
          >
            {{ item.text }}
          </option>
        </select>
      </div>

      <!-- 일 -->
      <div class="date_filter-date" v-if="interval === 'daily'">
        <v-menu
          v-model="dateMenuStart"
          :disabled="customPicker"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="formatStartDate"
              :class="{ active: customPicker === true }"
              v-bind="attrs"
              v-on="on"
              hide-details
              dense
              readonly
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="startDate"
            :min="minDate"
            :max="endDate"
            @change="setDate()"
            @input="dateMenuStart = false"
            :disabled="customPicker"
            color="#3943ff"
            header-color="#3943ff"
            :weekday-format="getDay"
            :month-format="getMonth"
            :title-date-format="getMonth"
            :header-date-format="getMonth"
          ></v-date-picker>
        </v-menu>
        <span>~</span>
        <v-menu
          v-model="dateMenuEnd"
          :disabled="customPicker"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="formatEndDate"
              :class="{ active: customPicker === true }"
              v-bind="attrs"
              v-on="on"
              hide-details
              dense
              readonly
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="endDate"
            :min="startDate"
            :max="today"
            @change="setDate()"
            @input="dateMenuEnd = false"
            :disabled="customPicker"
            color="#3943ff"
            header-color="#3943ff"
            :weekday-format="getDay"
            :month-format="getMonth"
            :title-date-format="getMonth"
            :header-date-format="getMonth"
          ></v-date-picker>
        </v-menu>
      </div>
      <!-- 주 -->
      <div class="date_filter-date" v-if="interval === 'weekly'">
        <v-menu
          v-model="dateMenuStart"
          :disabled="customPicker"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="formatStartDate"
              :class="{ active: customPicker === true }"
              v-bind="attrs"
              v-on="on"
              hide-details
              dense
              readonly
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="startDate"
            ref="startDate"
            :min="monthStartMin"
            :max="monthStartMax"
            @update:picker-date="setDate()"
            @input="dateMenuStart = false"
            :disabled="customPicker"
            color="#3943ff"
            header-color="#3943ff"
            :month-format="getMonth"
            reactive
          ></v-date-picker>
        </v-menu>
        <span>~</span>
        <v-menu
          v-model="dateMenuEnd"
          :disabled="customPicker"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="formatEndDate"
              :class="{ active: customPicker === true }"
              v-bind="attrs"
              v-on="on"
              hide-details
              dense
              readonly
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="endDate"
            ref="endDate"
            :min="startDate"
            :max="monthEndMax"
            @update:picker-date="setDate()"
            @input="dateMenuEnd = false"
            :disabled="customPicker"
            color="#3943ff"
            header-color="#3943ff"
            :month-format="getMonth"
            reactive
          ></v-date-picker>
        </v-menu>
      </div>
      <!-- 월 -->
      <div class="date_filter-date" v-if="interval === 'monthly'">
        <v-menu
          v-model="dateMenuStart"
          :disabled="customPicker"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="formatStartDate"
              :class="{ active: customPicker === true }"
              v-bind="attrs"
              v-on="on"
              hide-details
              dense
              readonly
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="startDate"
            ref="startDate"
            :min="yearStartMin"
            :max="yearStartMax"
            @update:picker-date="setDate()"
            @input="dateMenuStart = false"
            :disabled="customPicker"
            active-picker="YEAR"
            color="#3943ff"
            header-color="#3943ff"
            reactive
          ></v-date-picker>
        </v-menu>
        <span>~</span>
        <v-menu
          v-model="dateMenuEnd"
          :disabled="customPicker"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="formatEndDate"
              :class="{ active: customPicker === true }"
              v-bind="attrs"
              v-on="on"
              hide-details
              dense
              readonly
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="endDate"
            ref="endDate"
            :min="startDate"
            :max="yearEndMax"
            @update:picker-date="setDate()"
            @input="dateMenuEnd = false"
            :disabled="customPicker"
            active-picker="YEAR"
            color="#3943ff"
            header-color="#3943ff"
            reactive
          ></v-date-picker>
        </v-menu>
      </div>
      <div class="date_filter-btn search_btn">
        <button class="active" @click="changeDate(startDate, endDate)">
          검색
        </button>
      </div>
      <span class="comment">2018년부터 조회할 수 있습니다.</span>
    </div>
    <div
      style="cursor: pointer"
      @click.stop="changeSeven"
      class="ml-3 switch"
      id="not_style"
    >
      <!-- <v-switch
        inset
        v-model="seven"
        @change="changeSeven"
        label="요일분석"
      ></v-switch> -->
      <button class="promotion_alarm" :class="{ active: seven }"></button>
      <p class="promotion_alarm_label">요일분석</p>
    </div>
  </div>
</template>
<script>
  import moment from "moment";

  import { mapGetters } from "vuex";

  export default {
    name: "FilterView",
    mounted() {
      this.setQuery();
    },
    props: ["type"],
    data() {
      return {
        // v-date-picker 데이터 정의
        dateMenuStart: false,
        dateMenuEnd: false,

        today: moment().subtract(1, "d").format("YYYY-MM-DD"),
        minDate: "2018",

        monthStartMin: moment("2018").format("YYYY-MM") + "-NaN",
        monthStartMax: moment(this.endDate).format("YYYY-MM") + "-NaN",
        monthEndMax: moment().subtract(1, "d").format("YYYY-MM") + "-NaN",

        yearStartMin: moment("2018").format("YYYY") + "-NaN-NaN",
        yearStartMax: moment(this.endDate).format("YYYY") + "-NaN-NaN",
        yearEndMax: moment().subtract(1, "d").format("YYYY") + "-NaN-NaN",

        moment,

        //date
        startDate: null,
        endDate: null,

        customPicker: true,

        mobileKeywordActive: false,

        interval: "daily",
        seven: false,
        intervalItems: [
          {
            text: "일간",
            value: "daily",
          },
          {
            text: "주간",
            value: "weekly",
          },
          {
            text: "월간",
            value: "monthly",
          },
        ],

        dateType: "1",
        dateTypeItems: [
          {
            text: "1개월",
            value: "1",
            active: true,
            disabled: true,
          },
          {
            text: "3개월",
            value: "3",
            active: false,
            disabled: true,
          },
          {
            text: "1년",
            value: "year",
            active: false,
            disabled: false,
          },
          {
            text: "직접입력",
            value: "custom",
            active: false,
            disabled: false,
          },
        ],
        mobileDateType: [
          {
            text: "1년",
            value: "year",
            active: false,
            disabled: false,
          },
          {
            text: "직접입력",
            value: "custom",
            active: false,
            disabled: false,
          },
        ],
      };
    },
    watch: {
      // 달력 타입에 따라 날짜 포맷 다르게 변경(invaild value 방지)
      startDate(v) {
        if (this.interval === "weekly") {
          v = v.substr(0, 7);
          this.startDate = v;
        } else if (this.interval === "monthly") {
          v = v.substr(0, 4);
          this.startDate = v;
        }
      },
      endDate(v) {
        if (this.interval === "weekly") {
          v = v.substr(0, 7);
          this.endDate = v;
          this.monthStartMax = moment(this.endDate).format("YYYY-MM") + "-NaN";
        } else if (this.interval === "monthly") {
          v = v.substr(0, 4);
          this.endDate = v;
          this.yearStartMax = moment(this.endDate).format("YYYY") + "-NaN-NaN";
        }
      },

      // 달력 타입 변경(라이브러리에서 제공되지 않아서 강제변경)
      dateMenuStart(v) {
        if (this.interval === "weekly") {
          v &&
            this.$nextTick(
              () => (this.$refs.startDate.internalActivePicker = "MONTH")
            );
        } else if (this.interval === "monthly") {
          v &&
            this.$nextTick(
              () => (this.$refs.startDate.internalActivePicker = "YEAR")
            );
        }
      },
      dateMenuEnd(v) {
        if (this.interval === "weekly") {
          v &&
            this.$nextTick(
              () => (this.$refs.endDate.internalActivePicker = "MONTH")
            );
        } else if (this.interval === "monthly") {
          v &&
            this.$nextTick(
              () => (this.$refs.endDate.internalActivePicker = "YEAR")
            );
        }
      },

      keywordState() {
        this.pushQuery();
      },

      mobileKeywordState(v) {
        if (v !== "") {
          this.mobileKeywordActive = true;
        } else {
          this.mobileKeywordActive = false;
        }
      },

      // '월간, 주간 직접입력 선택 시' params 초기값 반영 막기
      // 월간(또는 주간) 및 직접입력 선택 후 키워드 입력하고 다시 주간(또는 월간)을 바꾸면 문제 생겨서 watch로 감시
      interval(v) {
        let dateType = this.dateTypeItems;
        for (let i = 0; dateType.length > i; i++) {
          if (dateType[i].value === "custom" && v === "monthly") {
            this.startDate = moment(this.startDate).format("YYYY");
            this.endDate = moment(this.endDate).format("YYYY");
          } else if (dateType[i].value === "custom" && v === "weekly") {
            this.startDate = moment(this.startDate).format("YYYY-MM");
            this.endDate = moment(this.endDate).format("YYYY-MM");
          }
        }
      },
    },
    computed: {
      ...mapGetters({
        keywordState: "keyword/keywordState",
        mobileKeywordState: "keyword/mobileKeywordState",
      }),
      // interval에 따른 날짜 포맷 스타일 변경
      formatStartDate() {
        let dayFormat = moment(this.startDate).format("YYYY. MM. DD");
        let monthFormat = moment(this.startDate).format("YYYY. MM");
        let yearFormat = moment(this.startDate).format("YYYY");

        if (this.interval === "weekly") return monthFormat;
        if (this.interval === "monthly") return yearFormat;
        return dayFormat;
      },
      formatEndDate() {
        let dayFormat = moment(this.endDate).format("YYYY. MM. DD");
        let monthFormat = moment(this.endDate).format("YYYY. MM");
        let yearFormat = moment(this.endDate).format("YYYY");

        if (this.interval === "weekly") return monthFormat;
        if (this.interval === "monthly") return yearFormat;
        return dayFormat;
      },
    },
    methods: {
      setQuery() {
        //쿼리설정
        this.interval = this.$route.query.interval
          ? this.$route.query.interval
          : this.interval;
        //시작
        this.startDate = this.$route.query.startDate
          ? this.$route.query.startDate
          : moment(this.today).subtract(1, "M").format("YYYY-MM-DD");

        //종료
        this.endDate = this.$route.query.endDate
          ? this.$route.query.endDate
          : this.today;
        //데이터 타입
        this.dateType = this.$route.query.dateType
          ? this.$route.query.dateType
          : this.dateType;

        //seven

        if (this.$route.query.seven === "false" || !this.$route.query.seven) {
          this.seven = false;
        } else {
          this.seven = true;
        }

        if (this.dateType === "custom") {
          this.datepicker = true;
          this.customPicker = false;
        }

        this.dateTypeItems.forEach((el) => {
          el.active = false;
          if (el.value == this.dateType) {
            el.active = true;
          }
        });

        let params = {
          interval: this.interval,
          startDate: this.startDate,
          endDate: this.endDate,
          dateType: this.dateType,
          seven: this.seven,
        };

        this.$store.commit("keyword/setParams", params);

        if (this.$route.query.keywords) {
          this.$store.commit("keyword/setKeyword", this.$route.query.keywords);
          this.chartLoading = true;
        }
      },
      pushQuery() {
        let query = {
          keywords: this.$store.state.keyword.keywords,
          interval: this.$store.state.keyword.interval,
          startDate: this.$store.state.keyword.startDate,
          endDate: this.$store.state.keyword.endDate,
          dateType: this.$store.state.keyword.dateType,
          seven: this.$store.state.keyword.by_seven,
        };
        if (this.type === "keywordManager" || this.type === "groupManage") {
          query.managerArr = this.$store.state.keyword.managerArr;
        }
        this.$router
          .push({
            query: query,
          })
          .catch(() => {});
      },

      changeDate(startDate, endDate) {
        let params = {
          interval: this.interval,
          startDate: startDate,
          endDate: endDate,
          dateType: this.$store.state.keyword.dateType,
          seven: this.seven,
        };

        this.$store.commit("keyword/setParams", params);

        if (this.keywordState.length > 0 || this.$route.name !== "HomeView") {
          this.pushQuery();
          this.$emit("getData");
        } else {
          this.$store.commit("controller/setMsg", "키워드를 입력해주세요!");
        }
        if (this.mobileKeywordActive) {
          if (this.$route.query.keywords || this.$route.name !== "HomeView") {
            let arr = [...this.$route.query.keywords, this.mobileKeywordState];
            this.$store.commit("keyword/setKeyword", arr);
            this.pushQuery();
            this.$emit("getData");
          }
        }
      },

      changeSeven() {
        let params = {
          interval: this.interval,
          startDate: this.startDate,
          endDate: this.endDate,
          dateType: this.dateType,
          seven: !this.seven,
        };
        this.$store.commit("keyword/setParams", params);

        if (this.keywordState.length > 0 || this.$route.name !== "HomeView") {
          this.pushQuery();
        }
      },
      getDay(date) {
        const daysOfWeek = ["일", "월", "화", "수", "목", "금", "토"];
        let i = new Date(date).getDay(date);
        return daysOfWeek[i];
      },
      getMonth(date) {
        const monthName = [
          "1월",
          "2월",
          "3월",
          "4월",
          "5월",
          "6월",
          "7월",
          "8월",
          "9월",
          "10월",
          "11월",
          "12월",
        ];
        let i = new Date(date).getMonth(date);
        return monthName[i];
      },

      setStore() {
        let startDate = "";
        let endDate = "";
        let dateType = "";

        // 키워드 검색 시 날짜 포맷 동일하도록 변경
        if (this.interval === "monthly") {
          startDate = moment(this.endDate).subtract(1, "Y").format("YYYY");
          endDate = moment(this.endDate).format("YYYY");
          dateType = "year";
        } else if (this.interval === "weekly") {
          startDate = moment(this.today).subtract(3, "M").format("YYYY-MM");
          endDate = moment(this.today).format("YYYY-MM");
          dateType = "3";
        } else if (this.interval === "daily") {
          startDate = moment(this.today).subtract(1, "M").format("YYYY-MM-DD");
          endDate = moment(this.today).format("YYYY-MM-DD");
          dateType = "1";
        }
        this.dateType = dateType;
        this.dateTypeItems.forEach((el) => {
          el.active = false;
          if (el.value === this.dateType) {
            el.active = true;
          }
        });

        this.startDate = startDate;
        this.endDate = endDate;
        this.dateType = dateType;

        let params = {
          interval: this.interval,
          startDate: startDate,
          endDate: endDate,
          dateType: dateType,
          seven: this.seven,
        };

        this.$store.commit("keyword/setParams", params);

        if (this.keywordState.length > 0 || this.$route.name !== "HomeView") {
          this.pushQuery();
        }
      },

      changeDateType(index) {
        //디자인 클래스 수정
        this.dateTypeItems.forEach((el) => {
          el.active = false;
        });
        this.dateTypeItems[index].active = true;

        // 키워드 검색 시 날짜 포맷 동일하도록 변경
        this.dateTypeItems[index].value === "custom"
          ? (this.customPicker = false)
          : (this.customPicker = true);

        if (this.dateTypeItems[index].value === "1") {
          if (this.interval === "daily") {
            this.startDate = moment(this.today)
              .subtract(1, "M")
              .format("YYYY-MM-DD");
            this.endDate = moment(this.today).format("YYYY-MM-DD");
          } else if (this.interval === "weekly") {
            this.startDate = moment(this.today)
              .subtract(1, "M")
              .format("YYYY-MM");
            this.endDate = moment(this.today).format("YYYY-MM");
          } else if (this.interval === "monthly") {
            this.startDate = moment(this.today).subtract(1, "M").format("YYYY");
            this.endDate = moment(this.today).format("YYYY");
          }
        } else if (this.dateTypeItems[index].value === "3") {
          if (this.interval === "daily") {
            this.startDate = moment(this.today)
              .subtract(3, "M")
              .format("YYYY-MM-DD");
            this.endDate = moment(this.today).format("YYYY-MM-DD");
          } else if (this.interval === "weekly") {
            this.startDate = moment(this.today)
              .subtract(3, "M")
              .format("YYYY-MM");
            this.endDate = moment(this.today).format("YYYY-MM");
          } else if (this.interval === "monthly") {
            this.startDate = moment(this.today).subtract(3, "M").format("YYYY");
            this.endDate = moment(this.today).format("YYYY");
          }
        } else if (this.dateTypeItems[index].value === "year") {
          if (this.interval === "daily") {
            this.startDate = moment(this.today)
              .subtract(1, "Y")
              .format("YYYY-MM-DD");
            this.endDate = moment(this.today).format("YYYY-MM-DD");
          } else if (this.interval === "weekly") {
            this.startDate = moment(this.today)
              .subtract(1, "Y")
              .format("YYYY-MM");
            this.endDate = moment(this.today).format("YYYY-MM");
          } else if (this.interval === "monthly") {
            this.startDate = moment(this.today).subtract(1, "Y").format("YYYY");
            this.endDate = moment(this.today).format("YYYY");
          }
        } else if (this.dateTypeItems[index].value === "custom") {
          if (this.interval === "daily") {
            this.startDate = moment(this.startDate).format("YYYY-MM-DD");
            this.endDate = moment(this.endDate).format("YYYY-MM-DD");
          } else if (this.interval === "weekly") {
            this.startDate = moment(this.startDate).format("YYYY-MM");
            this.endDate = moment(this.endDate).format("YYYY-MM");
          } else if (this.interval === "monthly") {
            this.startDate = moment(this.startDate).format("YYYY");
            this.endDate = moment(this.endDate).format("YYYY");
          }
        }

        let params = {
          interval: this.interval,
          startDate: this.startDate,
          endDate: this.endDate,
          dateType: this.dateTypeItems[index].value,
          seven: this.seven,
        };
        this.$store.commit("keyword/setParams", params);

        if (
          (this.keywordState.length > 0 || this.$route.name !== "HomeView") &&
          this.customPicker === true
        ) {
          this.pushQuery();
        }
      },

      setDate() {
        let params = {
          interval: this.interval,
          startDate: this.startDate,
          endDate: this.endDate,
          dateType: this.$store.state.keyword.dateType,
          seven: this.seven,
        };
        this.$store.commit("keyword/setParams", params);
      },

      setDateType() {
        // 키워드 검색 시 날짜 포맷 동일하도록 변경
        this.dateType === "custom"
          ? (this.customPicker = false)
          : (this.customPicker = true);

        if (this.dateType === "1") {
          if (this.interval === "daily") {
            this.startDate = moment(this.today)
              .subtract(1, "M")
              .format("YYYY-MM-DD");
            this.endDate = moment(this.today).format("YYYY-MM-DD");
          } else if (this.interval === "weekly") {
            this.startDate = moment(this.today)
              .subtract(1, "M")
              .format("YYYY-MM");
            this.endDate = moment(this.today).format("YYYY-MM");
          } else if (this.interval === "monthly") {
            this.startDate = moment(this.today).subtract(1, "M").format("YYYY");
            this.endDate = moment(this.today).format("YYYY");
          }
        } else if (this.dateType === "3") {
          if (this.interval === "daily") {
            this.startDate = moment(this.today)
              .subtract(3, "M")
              .format("YYYY-MM-DD");
            this.endDate = moment(this.today).format("YYYY-MM-DD");
          } else if (this.interval === "weekly") {
            this.startDate = moment(this.today)
              .subtract(3, "M")
              .format("YYYY-MM");
            this.endDate = moment(this.today).format("YYYY-MM");
          } else if (this.interval === "monthly") {
            this.startDate = moment(this.today).subtract(3, "M").format("YYYY");
            this.endDate = moment(this.today).format("YYYY");
          }
        } else if (this.dateType === "year") {
          if (this.interval === "daily") {
            this.startDate = moment(this.today)
              .subtract(1, "Y")
              .format("YYYY-MM-DD");
            this.endDate = moment(this.today).format("YYYY-MM-DD");
          } else if (this.interval === "weekly") {
            this.startDate = moment(this.today)
              .subtract(1, "Y")
              .format("YYYY-MM");
            this.endDate = moment(this.today).format("YYYY-MM");
          } else if (this.interval === "monthly") {
            this.startDate = moment(this.today).subtract(1, "Y").format("YYYY");
            this.endDate = moment(this.today).format("YYYY");
          }
        } else {
          if (this.interval === "daily") {
            this.startDate = moment(this.startDate).format("YYYY-MM-DD");
            this.endDate = moment(this.endDate).format("YYYY-MM-DD");
          } else if (this.interval === "weekly") {
            this.startDate = moment(this.startDate).format("YYYY-MM");
            this.endDate = moment(this.endDate).format("YYYY-MM");
          } else if (this.interval === "monthly") {
            this.startDate = moment(this.startDate).format("YYYY");
            this.endDate = moment(this.endDate).format("YYYY");
          }
        }

        let params = {
          interval: this.interval,
          startDate: this.startDate,
          endDate: this.endDate,
          dateType: this.dateType,
          seven: this.seven,
        };

        this.$store.commit("keyword/setParams", params);

        if (
          (this.keywordState.length > 0 || this.$route.name !== "HomeView") &&
          this.customPicker === true
        ) {
          this.pushQuery();
        }
      },
    },
  };
</script>

<style lang="css" scoped>
  .title-section {
    margin-bottom: 20px;
  }
  .search_btn {
    background-color: rgba(0, 0, 0, 0.2);
    color: white;
    border-radius: 21px;
    transition: all 0.3s;
  }
  .search_btn.active {
    background-color: #111;
  }
  .date_filter-btn select {
    display: none;
  }
  .table-wrap thead tr:last-child th {
    padding: 22px 0;
  }

  /* 날짜 라이브러리 강제 스타일 변경 S */
  .v-input:not(#not_style .v-input) {
    width: 148px !important;
    height: 42px !important;
    position: relative;
    margin-top: 0 !important;
    border-radius: 26px;
    border: 1px solid #e4e9f0;
    background-color: #f7f9fb;
    padding: 7px 20px 7px 20px !important;
    background-image: url(@/assets/img/common/calendar_icon_active.svg);
    background-repeat: no-repeat;
    background-position: 20px center;
  }
  .v-input:not(#not_style .v-input)::before {
    display: block;
    content: "";
    position: absolute;
    width: calc(100% - 40px);
    height: 3px;
    background-color: #f7f9fb;
    bottom: 5px;
    z-index: 2;
  }
  .v-input:not(#not_style .v-input):deep(input) {
    font-size: 14px !important;
    font-weight: bold;
    letter-spacing: -0.35px;
    text-align: right;
    color: #111 !important;
  }
  .v-input.active:not(#not_style .v-input),
  .v-input.active:not(#not_style .v-input)::before {
    background-color: #f1f4f8;
  }
  .v-input.active:not(#not_style .v-input):deep(input) {
    color: #bac0c6 !important;
  }
  .v-input.active:not(#not_style .v-input) {
    background-image: url(@/assets/img/common/calendar_icon_unactive.svg);
  }
  .v-picker:deep(.v-picker__title) {
    padding: 12px !important;
  }
  .v-picker:deep(.v-picker__title__btn:not(.v-picker__title__btn--active)) {
    opacity: 1 !important;
  }
  .v-picker:deep(.v-date-picker-title__year) {
    font-size: 14px !important;
    text-align: center !important;
    display: block !important;
    margin: 0 !important;
    color: #ffffff;
    font-weight: 700 !important;
  }
  .v-picker:deep(.v-date-picker-title__date) {
    display: none !important;
  }
  .disabled {
    color: #ccc;
    pointer-events: none;
  }
  #not_style.switch {
    height: 20px;
    margin-top: 0px;
  }
  .promotion_alarm {
    margin-right: 10px;
  }
  .promotion_alarm_label {
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    margin: 0;
    color: #111;
  }
  /* 날짜 라이브러리 강제 스타일 변경 E */

  @media all and (max-width: 1440px) {
    .date_filter-btn.search_btn button.active {
      width: 100%;
    }
    .date_filter-btn:not(.search_btn) button {
      display: none;
    }
    .date_filter-btn:not(.search_btn) {
      margin-bottom: 0;
    }
    .date_filter-btn select {
      display: block;
    }

    #not_style.switch {
      margin-top: 16px;
    }
    /* 날짜 라이브러리 강제 스타일 변경 S */
    .v-input:not(#not_style .v-input) {
      width: 100% !important;
      margin-bottom: 10px;
      background-image: none;
    }
    .v-input.active:not(#not_style .v-input) {
      background-image: none;
    }
    .v-input:not(#not_style .v-input)::after {
      display: block;
      position: absolute;
      left: 20px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 14px;
      font-weight: normal;
      color: #111;
    }
    .v-input:not(#not_style .v-input):first-of-type::after {
      content: "시작일";
    }
    .v-input:not(#not_style .v-input):last-of-type::after {
      content: "종료일";
    }
    .v-menu__content {
      min-width: 290px !important;
    }
    .date_filter-date span {
      display: none;
    }
    /* 날짜 라이브러리 강제 스타일 변경 E */
  }
  @media all and (max-width: 1023px) {
    .promotion_alarm {
      top: unset;
      right: 56px;
    }
    #not_style.switch {
      margin-bottom: 6px;
    }
  }
  @media all and (max-width: 767px) {
    /* 날짜 라이브러리 강제 스타일 변경 S */
    .v-menu__content {
      left: 50% !important;
      transform: translateX(-50%);
    }
    /* 날짜 라이브러리 강제 스타일 변경 E */
  }
</style>
