import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "@/views/HomeView";
import store from "../store";
import axios from "axios";
import { SetPCookie } from "@/utility/cookie";

Vue.use(VueRouter);

let router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/404",
      name: "notFound",
      component: () => import("@/views/NotFoundView.vue"),
    },
    {
      path: "/",
      name: "HomeWeb",
      component: () => import("@/views/web/HomeWeb"),
      beforeEnter: (to, from, next) => {
        const URLSearch = new URLSearchParams(location.search);
        const ch =
          URLSearch.get("ch") === null ? "direct" : URLSearch.get("ch");
        const ad =
          URLSearch.get("ad") === null ? "direct" : URLSearch.get("ad");

        axios.post(`v2/ads?ch=${ch}&ad=${ad}`);
        let mobile =
          /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent
          );
        //쿠키 굽자
        // if (document.referrer) {
        SetPCookie("visit");
        // }

        if (mobile === true) {
          next("/m");
        } else {
          next();
        }
      },
      meta: {
        requiresAuth: false,
        requiresAdmin: false,
      },
    },
    {
      path: "/m",
      name: "HomeMobile",
      component: () => import("@/views/web/HomeMobile"),
      beforeEnter: (to, from, next) => {
        let mobile =
          /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent
          );
        if (mobile !== true) {
          next("/");
        } else {
          next();
        }
      },
      meta: {
        requiresAuth: false,
        requiresAdmin: false,
      },
    },

    // 이메일 인증 ======================================================
    {
      path: "/email-check",
      name: "EmailIndex",
      component: () => import("@/views/email/EmailIndex.vue"),
      meta: {
        requiresAuth: true,
        requiresAdmin: false,
      },
    },

    // 체험하기 ======================================================
    {
      path: "/demo",
      name: "DemoView",
      component: () => import("@/views/demo/DemoView.vue"),
    },
    {
      path: "/demo/keyword/manager",
      name: "DemoKeywordManage",
      component: () => import("@/views/demo/DemoKeywordManage.vue"),
    },
    {
      path: "/demo/group/manager",
      name: "DemoGroupManage",
      component: () => import("@/views/demo/DemoGroupManage.vue"),
    },
    {
      path: "/demo/news/feeds",
      name: "DemoNewsFeeds",
      component: () => import("@/views/demo/DemoNewsFeed.vue"),
    },
    {
      path: "/demo/news/save",
      name: "DemoNewsSave",
      component: () => import("@/views/demo/DemoNewsSave.vue"),
    },

    // 회원가입 ======================================================
    {
      path: "/register",
      name: "RegisterIndex",
      component: () => import("@/views/web/register/Index.vue"),
    },
    {
      path: "/password",
      name: "PasswordView",
      component: () => import("@/views/web/register/PasswordView.vue"),
    },

    // 메인 ======================================================
    {
      path: "/keyword/analysis",
      name: "HomeView",
      component: HomeView,
      meta: {
        requiresAuth: true,
        requiresAdmin: false,
      },
    },
    {
      path: "/login/social",
      name: "SNS",
      component: () => import("@/views/SNS.vue"),
    },
    {
      path: "/login",
      name: "LoginView",
      component: () => import("@/views/LoginView.vue"),
      beforeEnter: async (to, from, next) => {
        if (store.getters["account/authActive"]) {
          if (store.getters["account/authSubscription"]) {
            return next({
              name: "HomeView",
            });
          }
          return next({
            name: "MemberInfo",
          });
        }

        next();
      },
    },
    {
      path: "/keyword/manager",
      name: "KeywordManage",
      component: () => import("@/views/KeywordManage.vue"),
      meta: {
        requiresAuth: true,
        requiresAdmin: false,
      },
    },
    {
      path: "/group/manager",
      name: "GroupManage",
      component: () => import("@/views/GroupManage.vue"),
      meta: {
        requiresAuth: true,
        requiresAdmin: false,
      },
    },
    // 뉴스 ======================================================
    {
      path: "/news/feeds",
      name: "FeedsView",
      component: () => import("@/views/news/FeedsView.vue"),
      meta: {
        requiresAuth: true,
        requiresAdmin: false,
      },
    },
    {
      path: "/news/save",
      name: "SaveView",
      component: () => import("@/views/news/SaveView.vue"),
      meta: {
        requiresAuth: true,
        requiresAdmin: false,
      },
    },
    // 멤버쉽 ======================================================
    {
      path: "/memeber/info",
      name: "MemberInfo",
      component: () => import("@/views/MemberInfo.vue"),
      meta: {
        requiresAuth: true,
        requiresAdmin: false,
      },
    },
    // 초대하기 ======================================================
    {
      path: "/memeber/invite",
      name: "MemberInvite",
      component: () => import("@/views/MemberInvite.vue"),
      meta: {
        requiresAuth: true,
        requiresAdmin: false,
      },
    },
    // 마이페이지 ======================================================
    {
      path: "/mypage",
      name: "MypageView",
      component: () => import("@/views/MypageView.vue"),
      meta: {
        requiresAuth: true,
        requiresAdmin: false,
      },
    },
    // 탈퇴 ======================================================
    {
      path: "/secession",
      name: "SecessionIndex",
      component: () => import("@/views/secession"),
      meta: {
        requiresAuth: true,
        requiresAdmin: false,
      },
    },
    // 해지 안내 ======================================================
    {
      path: "/cancel",
      name: "CancelPayment",
      component: () => import("@/views/cancel"),
      meta: {
        requiresAuth: true,
        requiresAdmin: false,
      },
    },
    // 결제 ======================================================
    {
      path: "/payment/cancel",
      name: "CancelView",
      component: () => import("@/views/subscription/Cancel.vue"),
      meta: {
        requiresAuth: false,
        requiresAdmin: false,
      },
    },
    {
      path: "/payment/success",
      name: "SuccessView",
      component: () => import("@/views/subscription/Success.vue"),
      meta: {
        requiresAuth: false,
        requiresAdmin: false,
      },
    },
    {
      path: "/payment/fail",
      name: "FailView",
      component: () => import("@/views/subscription/Fail.vue"),
      meta: {
        requiresAuth: false,
        requiresAdmin: false,
      },
    },
    {
      path: "*",
      redirect: "/404",
      // 아래처럼 바로 NotFound 경로를 매칭해도 됨
      // component: NotFound
    },
  ],
});

//전역설정
router.beforeEach(async (to, from, next) => {
  if (to.matched.some((route) => route.meta.requiresAuth)) {
    if (!store.getters["account/authActive"]) {
      // 유저없을때;
      next({
        name: "LoginView",
      });
    }

    // if (!store.getters["account/authUser"]?.email_verified_at) {
    //   next({
    //     name: "EmailIndex",
    //   });
    // } else {
    //   next();
    // }
  }
  next();
});

export default router;
