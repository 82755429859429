var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section',[(_vm.contectModal === true)?_c('div',{staticClass:"modal"},[_c('div',{staticClass:"modal_con"},[_c('div',[_c('div',{staticClass:"modal_header"},[_c('h2',[_vm._v("문의하기")]),_c('div',{staticClass:"modal_close_btn",on:{"click":_vm.closeModal}},[_c('img',{attrs:{"src":require("@/assets/img/modal_close.svg"),"alt":"모달 닫기 아이콘"}})])]),_c('form',{attrs:{"action":"submit"},on:{"submit":_vm.submit}},[_c('div',[_c('label',{attrs:{"for":"category"}},[_vm._v("문의유형")]),_c('select',{attrs:{"name":"category","id":"category"}},_vm._l((_vm.submitType),function(item,index){return _c('option',{key:index,domProps:{"value":item.value}},[_vm._v(" "+_vm._s(item.text)+" ")])}),0)]),_vm._m(0)]),_c('div',{staticStyle:{"text-align":"center"}},[_c('button',{on:{"click":_vm.submit}},[_vm._v("제출")])])])]),_c('div',{staticClass:"modal_bg",on:{"click":_vm.closeModal}})]):_vm._e()]),(_vm.floatingActive)?_c('section',{attrs:{"id":"floating"}},[(_vm.floatingInfo)?_c('article',{style:({ marginBottom: _vm.$isDemo === true ? '30px' : '0' })},[_vm._m(1),_c('img',{attrs:{"src":require("@/assets/img/floating_close.svg"),"alt":""},on:{"click":_vm.closePopup}})]):_vm._e(),_c('button',{style:({ marginBottom: _vm.$isDemo === true ? '30px' : '0' })},[_c('a',{attrs:{"href":"http://pf.kakao.com/_xjxefzxj/chat","target":"_blank"}},[_c('img',{style:({
            height:
              _vm.$route.name === 'HomeWeb'
                ? '75%'
                : _vm.$route.name === 'HomeMobile'
                ? '75%'
                : 'auto',
          }),attrs:{"src":require("@/assets/kakao_icon.svg"),"alt":""}})])])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('label',{attrs:{"for":"content"}},[_vm._v("문의내용")]),_c('textarea',{attrs:{"name":"content","id":"content","cols":"30","rows":"5","placeholder":"문의내용을 입력해 주세요 "}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" 팅서치에 대해 궁금하신가요? "),_c('br',{staticClass:"mo"}),_vm._v(" 언제든지 편하게 문의주세요! ")])
}]

export { render, staticRenderFns }