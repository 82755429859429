import account from "./account";
import menu from "./menu";
import keyword from "./keyword";
import controller from "./controller";

export default {
  account,
  menu,
  keyword,
  controller,
};
