export default {
  namespaced: true,
  state: {
    active: false,
    message: "",
    type: "",
  },
  getters: {
    activeMessage(state) {
      return state.message;
    },
    active(state) {
      return state.active;
    },
    type(state) {
      return state.type;
    },
  },
  mutations: {
    setCloseMsg(state) {
      state.active = false;
    },
    setMsg(state, payload) {
      state.type = "defult";
      if (typeof payload === "string") {
        state.message = payload;
      } else {
        state.message = payload.message;
        state.type = payload.type === "error" ? "error" : "defult";
      }
      state.active = true;

      setTimeout(() => {
        state.active = false;
      }, 2500);
    },
  },
};
