<template>
  <div>
    <RelationModal
      @closeRelateModal="closeRelateModal"
      :relateModal="relateModal"
      :relateInfo="relateInfo"
    />

    <section class="section_wrap">
      <div class="container-section">
        <h2 class="title-section">키워드 분석</h2>

        <SearchBar />

        <FilterView @getData="getData" />

        <ChartGraph :data="statsData" :loading="chartLoading" />

        <PlaceView
          :data="statsData"
          @showRelateModal="showRelateModal"
          :loading="chartLoading"
        />

        <CompetitionView :data="statsData" />

        <PopulationView :data="statsData" :keyArr="dataKeyArr" />
      </div>
    </section>
  </div>
</template>
<script>
  import { mapActions, mapGetters } from "vuex";
  import { axiosService } from "@/api";

  import ChartGraph from "@/components/chart/chartView.vue";
  import PlaceView from "@/components/table/PlaceView.vue";
  import CompetitionView from "@/components/table/CompetitionView.vue";
  import PopulationView from "@/components/table/PopulationView.vue";
  import SearchBar from "@/components/search/SearchBar.vue";
  import FilterView from "@/components/common/FilterView.vue";
  import RelationModal from "@/components/modal/RelationModal.vue";

  export default {
    name: "HomeView",
    components: {
      ChartGraph,
      PlaceView,
      CompetitionView,
      PopulationView,
      SearchBar,
      RelationModal,
      FilterView,
    },
    data() {
      return {
        statsData: [],
        interval: "daily",

        endOpen: false,
        queryWrap: {},
        query: {},

        relateModal: false,
        relateInfo: [],

        chartLoading: false, //차트 로딩 컨트롤

        windowWidth: window.innerWidth,

        dataKeyArr: [],
      };
    },

    computed: {
      ...mapGetters({
        authSubscription: "account/authSubscription",
      }),
    },
    mounted() {
      this.getData();

      this.$nextTick(() => {
        window.addEventListener("resize", this.onResize);

        if (this.authSubscription === false) {
          this.$router.push({ name: "MemberInfo" });
          // this.$router.push({ name: "GuideHomeView" });
        }
      });
    },

    methods: {
      readyMessage() {
        this.$store.commit("controller/setMsg", "준비중입니다");
        return;
      },

      onResize() {
        this.windowWidth = window.innerWidth;
      },
      ...mapActions({
        getStatsData: "keyword/getStatsData",
      }),

      async getData() {
        this.chartLoading = true;

        this.getStatsData()
          .then(async (res) => {
            let data = {};
            if (res !== undefined) {
              data = { ...res };
              await res.data?.map(async (item, index) => {
                let total = await this.getTotalAmount(item.search);
                data.data[index].total = total;
              });

              let dd = data.data?.sort(function (a, b) {
                return b.total - a.total;
              });
              dd.forEach((element) => {
                this.dataKeyArr.push(element.keyword);
              });
              data.data = dd;
              this.statsData = data;
            } else {
              this.statsData = res;
            }
            this.chartLoading = false;
          })
          .catch(() => {
            this.chartLoading = false;
          });
      },

      getTotalAmount(data) {
        let count = 0;
        for (let i = 0; data.length > i; i++) {
          count = count + data[i].amount;
        }
        return count;
      },

      //연관검색어 모달 수정
      closeRelateModal(val) {
        this.relateModal = val;
        this.relateInfo = [];
      },

      async showRelateModal(item) {
        this.relateModal = true;
        try {
          this.relateInfo = [];
          let items = await axiosService.get(
            `v2/search/keyword/related/${item.keyword}`
          );
          this.relateInfo = items.data.autoComplete;
        } catch (err) {
          this.$store.commit("controller/setMsg", {
            message: "잠시 후 다시 시도해주세요",
            type: "error",
          });
        }
      },
    },
  };
</script>

<style lang="css" scoped>
  @import url("@/css/common.css");
  @import url("@/css/media.css");
  .title-section {
    margin-bottom: 20px;
  }
  .search_btn {
    background-color: rgba(0, 0, 0, 0.2);
    color: white;
    border-radius: 21px;
    transition: all 0.3s;
  }
  .search_btn.active {
    background-color: #111;
  }
  .date_filter-btn select {
    display: none;
  }
  .table-wrap thead tr:last-child th {
    padding: 22px 0;
  }

  @media all and (max-width: 1440px) {
    .date_filter-btn.search_btn button.active {
      width: 100%;
    }
    .date_filter-btn:not(.search_btn) button {
      display: none;
    }
    .date_filter-btn:not(.search_btn) {
      margin-bottom: 0;
    }
    .date_filter-btn select {
      display: block;
    }
  }
  @media all and (max-width: 767px) {
    /* 날짜 라이브러리 강제 스타일 변경 S */
    .v-menu__content {
      left: 50% !important;
      transform: translateX(-50%);
    }
  }
</style>
