// import router from "@/router/index";
import { axiosService } from "@/api/index";
import store from "@/store";
import moment from "moment";
// import moment from "moment";

import AppUtility from "@/utility/app";

export default {
  namespaced: true,

  state: {
    token: null,
    user: null,
    subscription: true,
    record: false,
  },

  getters: {
    authToken(state) {
      return state.token;
    },
    authUser(state) {
      return state.user;
    },
    authActive(state) {
      return state.user && state.token;
    },
    authSubscription(state) {
      return state.subscription;
    },
    authRecord(state) {
      //구독이였던 사람 표시
      return state.record;
    },
  },

  mutations: {
    setToken(state, token) {
      state.token = token;
    },
    setUser(state, user) {
      state.user = user;
    },
    setSubscript(state, active) {
      state.subscription = active;
    },
    setRecord(state, active) {
      state.record = active;
    },
  },

  actions: {
    async attempt({ commit, state, dispatch }, token) {
      if (token) {
        commit("setToken", token);
      }
      if (!state.token) {
        return;
      }
      try {
        let response = await axiosService.get("v2/auth/me");

        let endDate = moment(response.data.subscription?.end_at).format(
          "YYYY-MM-DD"
        ); //종료 날짜

        let data2 = moment(moment()).isBefore(endDate); //기간이 지났는지 안지났는지 false 인 경우 안지남 / 없음 true 인 경우 기간 지남

        if (response.data.subscription !== null && data2 === false) {
          commit("setRecord", true);
        } else {
          commit("setRecord", false);
        }

        if (response.data.isSubscriber && data2) {
          commit("setSubscript", true);
        } else {
          commit("setSubscript", false);
        }
        commit("setUser", response.data);

        return response;
      } catch (error) {
        let active = await AppUtility("logout");
        if (!active) {
          dispatch("logout");
        }
      }
    },

    async login({ dispatch }, payload) {
      if (payload.email === "" || payload.password === "") {
        store.commit("controller/setMsg", {
          message: "로그인 정보를 다시 확인해주세요",
          type: "error",
        });
      } else {
        await axiosService.post("v2/auth/login", payload).then((res) => {
          localStorage.setItem("t_access_token", res.data.access_token);
          dispatch("attempt", res.data.access_token).then((res2) => {
            if (res2.data.isSubscriber) {
              location.reload({ name: "HomeView" });
            } else {
              location.reload({ name: "MemberInfo" });
            }
          });
        });
      }
    },
    async logout({ commit }) {
      commit("setToken", null);
      commit("setUser", null);
      commit("setSubscript", null);
      localStorage.removeItem("t_access_token");
    },
  },
};
