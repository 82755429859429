<template>
  <div style="height: 100%">
    <lottie
      :options="options"
      style="height: 100%; width: 44px"
      @animCreated="handleAnimation"
    />
  </div>
</template>

<script>
  import Lottie from "vue-lottie";
  import * as animationData from "@/json/loading/loading.json";
  export default {
    name: "LoadingView",
    props: ["hegiht"],
    components: {
      Lottie,
    },
    data() {
      return {
        options: { animationData: animationData },
        animationSpeed: 1,
      };
    },
    methods: {
      handleAnimation: function (anim) {
        this.anim = anim;
      },
    },
  };
</script>

<style></style>
0
