/**
 * 앱 호출관련 로직 true / false 반환
 * @param {*} type 앱 호출 파마미터 값을 입력해주세요 
 * @returns 
 */
export const AppUtility = (type) => {
  try {
    window.webToAppEvent.postMessage(type);
    return true;
  } catch (err) {
    console.log(err);
    return false;
  }
};

export default AppUtility;
