<template>
  <div class="header_search-gnb">
    <form action="">
      <div class="search_form-wrap">
        <div class="icon_ham">
          <span class="menu_btn" @click="showNav"></span>
          <h2 class="mobile-title" style="margin-bottom: 0">{{ title }}</h2>
          <!-- update -->
          <div class="free mo" v-show="free === true">무료이용중</div>
        </div>
      </div>
    </form>

    <div class="header_gnb-wrap">
      <!-- update -->
      <div class="free pc" v-if="free === true">무료이용중</div>
      <ul class="header_gnb-text">
        <li v-for="(item, index) in subMenu" :key="index">
          <div @click="setMsg">
            {{ item.text }}
          </div>
        </li>
      </ul>

      <ul class="header_gnb-icon">
        <li v-for="(item, index) in userMenuItems" :key="index">
          <div @click="setMsg">
            <img :src="item.icon" :alt="item.text" />
          </div>
        </li>
        <li style="cursor: pointer" @click="logout">
          <img src="@/assets/img/common/icon_gnb-logout.svg" alt="로그아웃" />
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
  import { mapActions, mapGetters } from "vuex";
  import AppUtility from "@/utility/app";
  export default {
    name: "HeaderSearchBar",
    props: ["type"],

    data() {
      return {
        subMenu: [
          {
            text: "멤버쉽안내",
            path: "/memeber/info",
          },
          // {
          //   text: "초대하기",
          //   path: "/memeber/invite",
          // },
        ],
        userMenuItems: [
          // {
          //   text: "홈",
          //   path: "/",
          //   icon: require("@/assets/img/common/icon_gnb-home.svg"),
          // },
          {
            text: "마이페이지",
            path: "/mypage",
            icon: require("@/assets/img/common/icon_gnb-mypage.svg"),
          },
        ],
        keywordList: [],
        query: {},
        title: "",

        free: false,
      };
    },

    computed: {
      ...mapGetters({
        me: "account/authUser",
      }),
    },
    mounted() {
      let to = this.$route;
      //표시
      if (to.name === "DemoView") {
        this.free = false;
      } else {
        this.free = true;
      }

      if (this.me !== null) {
        if (this.me.subscription?.flag !== "event") {
          this.free = false;
        } else {
          this.free = true;
        }
      }
      // Route 수정 :: 페이지 헤더 부분에 표시됩니다.
      if (
        to.name === "HomeView" ||
        to.name === "GuideHomeView" ||
        to.name === "DemoView"
      ) {
        this.title = "키워드 분석";
      } else if (to.name === "DemoKeywordManage") {
        this.title = "키워드 관리";
      } else if (to.name === "DemoGroupManage") {
        this.title = "그룹 관리";
      } else if (to.name === "MemberInfo") {
        this.title = "멤버쉽안내";
      } else if (to.name === "MemberInvite") {
        this.title = "초대하기";
      } else if (to.name === "MypageView") {
        this.title = "마이페이지";
      } else if (to.name === "DemoNewsFeeds") {
        this.title = "피드";
      } else if (to.name === "DemoNewsSave") {
        this.title = "보관함";
      }

      if (to.name === "SecessionIndex") {
        this.title = "회원탈퇴";
      }

      if (to.name === "CancelPayment") {
        this.title = "해지신청";
      }

      if (typeof this.$route.query.keywords === "string") {
        this.search = this.$route.query.keywords
          ? [this.$route.query.keywords]
          : [];
      } else {
        this.search = this.$route.query.keywords
          ? this.$route.query.keywords
          : [];
      }
      this.$store.commit("keyword/setKeyword", this.search);
    },
    watch: {
      $route(to) {
        if (
          to.name === "HomeView" ||
          to.name === "GuideHomeView" ||
          to.name === "DemoView"
        ) {
          this.title = "키워드 분석";
        } else if (to.name === "DemoKeywordManage") {
          this.title = "키워드 관리";
        } else if (to.name === "DemoGroupManage") {
          this.title = "그룹 관리";
        } else if (to.name === "MemberInfo") {
          this.title = "멤버쉽안내";
        } else if (to.name === "MemberInvite") {
          this.title = "초대하기";
        } else if (to.name === "MypageView") {
          this.title = "마이페이지";
        } else if (to.name === "DemoNewsFeeds") {
          this.title = "피드";
        } else if (to.name === "DemoNewsSave") {
          this.title = "보관함";
        }

        if (to.name === "SecessionIndex") {
          this.title = "회원탈퇴";
        }
        if (to.name === "CancelPayment") {
          this.title = "해지신청";
        }
      },
    },
    methods: {
      ...mapActions({
        logoutAction: "account/logout",
      }),
      setMsg() {
        this.$emit("setMsg");
      },
      async logout() {
        let active = await AppUtility("logout");
        if (!active) {
          if (this.$isDemo) {
            location.href = "/";
          } else {
            this.logoutAction().then(() => {
              this.$router
                .replace({
                  path: "/login",
                })
                .catch(() => {});
            });
          }
        }
      },
      showNav() {
        this.$store.commit("menu/toggleMenu", true);
      },
    },
  };
</script>
<style lang="css" scoped>
  .header_gnb-wrap .free {
    position: relative;
    right: 16px;
    background-color: #eaecff;
    border-radius: 17px;
    height: 33px;
    width: 88px;
    margin-right: 16px;
    text-align: center;
    line-height: 31px;
    font-size: 12px;
    margin-top: 4px;
    font-weight: 900;
    color: #3943ff;
  }
  .mo {
    display: none;
  }
  @media screen and (max-width: 1024px) {
    .mo {
      display: block;
    }
  }
</style>
