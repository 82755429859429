<template>
  <div>
    <div v-if="active === true" class="modal">
      <div class="modal_con pass">
        <span v-if="closeBtn === 'active'" @click="activeCloseTopBtn">
          <img src="@/assets/img/mypage/close-x-24.svg" alt="" />
        </span>
        <div>
          <div v-if="checkType === 'round'" class="sucess_box">
            <img src="@/assets/img/check_box_succes.svg" alt="" />
          </div>
          <div v-else-if="checkType === 'info'" class="mb-4">
            <img src="@/assets/img/check_box_info.svg" alt="" />
          </div>
          <div v-else class="triangle">
            <img src="@/assets/img/triangle_icon.svg" alt="" />
          </div>
          <div class="sucess_con">
            <h3
              v-if="type === 'demo'"
              style="margin-bottom: 8px; font-size: 22px"
            >
              {{ title }}
            </h3>
            <h3 v-else>{{ title }}</h3>
            <p
              v-html="demoText"
              v-if="text !== ''"
              :class="{ demo: type === 'demo' }"
            ></p>

            <!-- 만료일 추가 :secession 탈퇴인경우 -->

            <article
              class="secessionDateWrap"
              v-if="secession === true && secessionDate !== false"
            >
              <p><span>서비스 만료일</span> {{ secessionDate }}</p>
            </article>
          </div>
          <button @click="modalFalse">{{ button }}</button>
        </div>
      </div>
      <div class="modal_bg" @click="modalFalse('bg')"></div>
    </div>
  </div>
</template>
<script>
  export default {
    name: "ComfirmModal",
    props: [
      "checkType",
      "title",
      "text",
      "button",
      "active",
      "type",
      "closeBtn",
      "secession",
      "secessionDate",
    ],
    methods: {
      modalFalse(type) {
        let value = type;
        this.$emit("modalClose", value);
      },
      activeCloseTopBtn() {
        this.$emit("closeTopBtn", false);
      },
    },
    data() {
      return {
        demoText: "",
      };
    },
    mounted() {
      if (this.type === "demo") {
        this.demoText = this.text.split("\n").join("<br />");
      } else {
        this.demoText = this.text;
      }
    },
  };
</script>
<style lang="css" scoped>
  /* modal */
  .modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
    transition: all 0.3s ease;
  }
  .modal_con {
    position: fixed;
    background-color: #fff;
    border-radius: 30px;
    max-width: 800px;
    width: calc(100% - 32px);
    height: 600px;
    left: 50%;
    top: 50%;
    z-index: 99;
    padding: 40px;
    box-sizing: border-box;
    transform: translate(-50%, -50%);
  }
  .modal_con.pass {
    max-width: 380px;
    text-align: center;
    height: auto;
    box-sizing: border-box;
  }
  .modal_con.pass > span {
    width: 24px;
    height: 24px;
    position: absolute;
    right: 40px;
  }
  .modal_con.pass button {
    height: 52px;
    width: 160px;
    background-color: #3943ff;
    border-radius: 26px;
    color: white;
    font-size: 16px;
    font-weight: bold;
  }
  .modal_con.pass .sucess_con h3 {
    font-size: 22px;
    margin-bottom: 24px;
    font-weight: bold;
  }
  .modal_bg {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 88;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.3);
  }
  .modal_header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  }
  .modal_header h2 {
    font-size: 20px;
    font-weight: bold;
    color: #111;
  }
  .modal_header .modal_close_btn {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  .modal_header .modal_text {
    height: 475px;
    width: 100%;
    overflow: auto;
    background-color: pink;
  }

  /*  */
  .sucess_box {
    width: 60px;
    height: 60px;
    border: solid 4px #3943ff;
    border-radius: 50%;
    position: relative;
    margin: 0 auto;
    margin-bottom: 20px;
  }
  .sucess_box img {
    width: 24px;
    height: 24px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .sucess_con {
    text-align: center;
  }
  .sucess_con h3 {
    margin-bottom: 14px;
    font-size: 22px;
    color: #111;
  }
  .sucess_con > p {
    color: #bac0c6;
    font-size: 14px;
    margin-bottom: 24px;
  }
  .white_box_wrap .login_btn {
    display: block;
    width: 160px;
    margin: 0 auto;
  }

  .triangle {
    margin-bottom: 16px;
  }
  .sucess_con p.demo {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #111;
  }

  /* 회원탈퇴부분 바텀값이 좀 다름 */
  #unRegister .modal_con.pass .sucess_con h3 {
    margin-bottom: 14px;
  }
  #reRegister .modal_con.pass .sucess_con h3 {
    margin-bottom: 14px;
  }

  /* 탈퇴 만료일 */
  .secessionDateWrap {
    padding: 16px 0;
    text-align: center;
    background-color: #f5f5ff;
    border-radius: 8px;
    margin-bottom: 24px;
  }
  .secessionDateWrap p {
    margin-bottom: 0;
    color: #6068ff;
  }
  .secessionDateWrap p span {
    font-weight: bold;
    color: #6068ff;
    margin-right: 8px;
  }
  @media screen and (max-width: 1024px) {
    .modal_con.pass .sucess_con h3 {
      font-size: 18px;
    }
    .modal_con.pass button {
      height: 42px;
      width: 120px;
    }
    .sucess_box {
      margin-bottom: 16px;
    }
    .modal_con {
      padding: 40px 20px;
    }
  }
</style>
