var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"search_form-wrap searchBar"},[_c('div',{staticClass:"autoComplate_web",style:({ display: _vm.autoList === true ? 'block' : 'none' })},[(_vm.comboboxItems?.length > 0)?_c('ul',_vm._l((_vm.comboboxItems),function(item,index){return _c('li',{key:index,staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.addSearchItem('search', item)}}},[_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('div',[_vm._v(" "+_vm._s(item)+" ")])])])}),0):_vm._e()]),_c('div',{staticClass:"web_search_wrap"},[_c('div',{staticClass:"icon_search"},[_c('span',{on:{"click":function($event){return _vm.addSearchItem('webSearch', _vm.inputKeyword)}}})]),_c('div',{staticClass:"web_search_input"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputKeyword),expression:"inputKeyword"}],attrs:{"type":"search","id":"webKeyword","autocomplete":"off","maxlength":"20","placeholder":"최대 20개까지 복수키워드 분석이 가능합니다. 키워드 입력 후 ‘Tab’ 키를 누르시면 구분됩니다."},domProps:{"value":(_vm.inputKeyword)},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab"))return null;return _vm.addSearchItem('webSearch', _vm.inputKeyword)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.addSearchItem('webSearch', _vm.inputKeyword)}],"keyup":function($event){return _vm.searchInputBoxValue('web')},"input":function($event){if($event.target.composing)return;_vm.inputKeyword=$event.target.value}}}),(_vm.inputKeyword !== '')?_c('div',{staticClass:"removeKeywordAll"},[_c('div',{on:{"click":function($event){_vm.inputKeyword = '';
              _vm.autoList = false;}}},[_c('img',{attrs:{"src":require("@/assets/img/common/delete-btn.svg"),"alt":""}})])]):_vm._e()])]),_c('div',{staticClass:"bg",style:({ display: _vm.autoList === true ? 'block' : 'none' }),on:{"click":function($event){_vm.autoList = false}}})]),(_vm.keywordArr.length > 0)?_c('div',{staticClass:"web_search_Item searchBar",style:({
      height: _vm.showKeyword ? 'auto' : '42px',
    })},[_c('ul',_vm._l((_vm.keywordArr),function(item,index){return _c('li',{key:index},[_c('button',[_c('p',{staticClass:"search_item_txt"},[_vm._v("# "+_vm._s(item))]),_c('img',{attrs:{"src":require("@/assets/img/common/icon_chip_close.svg"),"alt":""},on:{"click":function($event){return _vm.removeKeyword(index)}}})])])}),0),_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"removeAll",on:{"click":_vm.removeKeywordAll}},[_vm._m(0)]),(_vm.showBtnActive)?_c('div',{staticStyle:{"margin-left":"8px"}},[_c('button',{on:{"click":function($event){_vm.showKeyword = !_vm.showKeyword}}},[_c('img',{style:({
              transform: _vm.showKeyword ? 'rotate(180deg)' : 'rotate(0)',
            }),attrs:{"src":require("@/assets/img/common/slide_btn.svg"),"alt":""}}),(!_vm.showKeyword)?_c('p',[_vm._v("펴기")]):_c('p',[_vm._v("접기")])])]):_vm._e()])]):_vm._e(),_c('div',{staticClass:"search_form-wrap",attrs:{"id":"mo_searchBar"}},[(_vm.autoList)?_c('div',{staticClass:"autoComplate",style:({ display: _vm.autoList === true ? 'block' : 'none' }),on:{"focus":function($event){_vm.autoList = true}}},[(_vm.comboboxItems?.length > 0)?_c('ul',{staticStyle:{"top":"0","padding":"0"}},_vm._l((_vm.comboboxItems),function(item,index){return _c('li',{key:index,staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.addSearchItem('search', item)}}},[_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('div',[_vm._v(" "+_vm._s(item)+" ")])])])}),0):_vm._e()]):_vm._e(),_c('span',{staticClass:"small_search_icon"}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputKeyword),expression:"inputKeyword"}],attrs:{"type":"search","id":"mobileKeyword","autocomplete":"off","placeholder":"최대 20개까지 복수키워드 분석가능","maxlength":"20"},domProps:{"value":(_vm.inputKeyword)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.addSearchItem('moSearch', _vm.inputKeyword)},"keyup":function($event){return _vm.searchInputBoxValue('mobile')},"input":function($event){if($event.target.composing)return;_vm.inputKeyword=$event.target.value}}}),(_vm.inputKeyword !== '')?_c('div',{staticClass:"removeKeywordAll",staticStyle:{"z-index":"2"}},[_c('button',{on:{"click":function($event){_vm.inputKeyword = '';
          _vm.autoList = false;}}},[_c('img',{attrs:{"src":require("@/assets/img/common/delete-btn.svg"),"alt":""}})])]):_vm._e(),(_vm.buttonActive)?_c('div',{on:{"click":_vm.setNewsKeyword}},[_c('button',{staticClass:"blue_btn"},[_vm._v("키워드 등록")])]):_vm._e(),(_vm.keywordArr.length > 0)?_c('div',{staticStyle:{"overflow":"hidden","margin-top":"16px"},style:({
        height: _vm.showKeywordMo ? 'auto' : _vm.showBtnActiveMo ? '72px' : 'auto',
      })},[_c('div',{staticClass:"chip_box"},[_c('div',{staticClass:"removeAll",staticStyle:{"margin":"0 4px 4px 0"},on:{"click":_vm.removeKeywordAll}},[_vm._m(1)]),_vm._l((_vm.keywordArr),function(item,index){return _c('v-chip',{key:index,staticStyle:{"border":"solid 1px #e4e9f0","box-sizing":"border-box","margin":"0 4px 4px 0","padding":"9px 7px 9px 12px","height":"34px"},attrs:{"color":"#f7f9fb"}},[_c('p',{staticStyle:{"font-size":"12px","line-height":"34px"}},[_vm._v(" "+_vm._s(item)+" ")]),_c('v-icon',{staticStyle:{"font-size":"16px","color":"#bac0c6","margin-left":"10px","line-height":"34px","margin-bottom":"0"},on:{"click":function($event){return _vm.removeKeyword(index)}}},[_vm._v(" mdi-close")])],1)})],2)]):_vm._e(),_c('article',[(_vm.showBtnActiveMo)?_c('div',{style:({ marginTop: _vm.showKeywordMo ? '14px' : '16px' })},[_c('button',{staticStyle:{"display":"flex","margin":"0 auto","font-size":"14px","font-weight":"bold"},on:{"click":function($event){_vm.showKeywordMo = !_vm.showKeywordMo}}},[(!_vm.showKeywordMo)?_c('p',[_vm._v("펴기")]):_c('p',[_vm._v("접기")]),_c('img',{style:({
              transform: _vm.showKeywordMo ? 'rotate(180deg)' : 'rotate(0)',
            }),attrs:{"src":require("@/assets/img/common/slide_btn.svg"),"alt":""}})])]):_vm._e()])]),(_vm.autoList)?_c('div',{staticClass:"bg",on:{"click":function($event){_vm.autoList = false}}}):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticStyle:{"width":"42px","padding":"12px"}},[_c('img',{staticStyle:{"display":"block"},attrs:{"src":require("@/assets/img/common/icon_remove_all.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('button',[_c('img',{attrs:{"src":require("@/assets/img/common/icon_remove_all_m.svg"),"alt":""}})])
}]

export { render, staticRenderFns }