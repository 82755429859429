import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import axios from "axios";
import moment from "moment";
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
import vuetify from "./plugins/vuetify";

// import { SetPCookie } from "@/utility/cookie";
// import { getCookie } from "@/utility/cookie";

Object.defineProperty(Vue.prototype, "$moment", { value: moment }); // use moment globally, like this.$moment()

// const URLSearch = new URLSearchParams(location.search);

Vue.use(Antd);
window.axios = require("axios");
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common["Content-Encoding"] = "gzip, deflate";
axios.defaults.headers.common["Client-Key"] =
  "927B7WKsSQm0rXdPhMAVo7JPYj2UKOxifvQGpCKtEBIEZFL6KyKu3o79vXaFum6h";
axios.defaults.baseURL = process.env.VUE_APP_API_URL;

Vue.prototype.axios = window.axios;

const isMobileDevice =
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  )
    ? true
    : false;

const isSafari =
  /constructor/i.test(window.HTMLElement) ||
  (function (p) {
    return p.toString() === "[object SafariRemoteNotification]";
  })(
    !window["safari"] ||
      (typeof safari !== "undefined" && window["safari"].pushNotification)
  );

const isDemo = location.pathname.indexOf("/demo") !== -1 ? true : false;

Object.defineProperty(Vue.prototype, "$isMobileDevice", {
  value: isMobileDevice,
});

Object.defineProperty(Vue.prototype, "$isSafari", {
  value: isSafari,
});

Object.defineProperty(Vue.prototype, "$isDemo", {
  value: isDemo,
});
store
  .dispatch("account/attempt", localStorage.getItem("t_access_token"))
  .then(() => {
    //쿠키 굽자
    // if (document.referrer) {
    //   SetPCookie("visit");
    // }

    new Vue({
      router,
      store,
      axios,
      vuetify,
      render: (h) => h(App),
    }).$mount("#app");
  });
