<template>
  <section class="modal" v-if="relateModal">
    <div class="content">
      <hgroup>
        <h3>연관 검색어</h3>
        <button class="close_btn" @click="closeModal"></button>
      </hgroup>
      <div class="table-wrap thead-1" style="overflow: auto">
        <table
          class="table"
          style="max-width: 520px; width: 90%; margin: 0 auto"
        >
          <thead>
            <tr>
              <th class="remove_after" style="width: 80px !important"></th>
              <th>키워드명</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in relateInfo" :key="index">
              <th>{{ index + 1 }}</th>
              <td>{{ item }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div @click="closeModal" class="modal_bg"></div>
  </section>
</template>

<script>
  export default {
    props: ["relateInfo", "relateModal"],
    data() {
      return {};
    },
    mounted() {},
    methods: {
      closeModal() {
        this.$emit("closeRelateModal", false);
      },
    },
  };
</script>

<style scoped leng="css">
  hgroup {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  hgroup {
    margin-bottom: 16px;
  }
  hgroup h3 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 0;
  }
  table {
    width: calc(100% - 8px) !important;
    float: left;
  }
  .table-wrap {
    height: 57vh;
    max-height: calc(100% - 80px);
  }
  .modal {
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    z-index: 999;
  }
  .modal .modal_bg {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
  }
  .modal .content {
    max-width: 450px;
    height: auto;
    max-height: 80vh;
    width: calc(100% - 32px);
    background-color: #fff;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 30px;
    padding: 40px;
    overflow: auto;
    box-sizing: border-box;
  }
  @media screen and (max-width: 450px) {
    .modal .content {
      padding: 24px 20px;
    }
  }
</style>
