export default {
  namespaced: true,

  state: {
    active: false,
    overlayMenu: true,
    experience: true,
  },

  getters: {
    menuActive(state) {
      return state.active;
    },
    overlayMenu(state) {
      return state.overlayMenu;
    },
  },

  mutations: {
    toggleMenu(state, payload) {
      state.active = payload;
    },
    setOverlayMenu(state, payload) {
      state.overlayMenu = payload;
    },
  },
};
