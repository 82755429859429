var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"white_box",staticStyle:{"background":"#fff"}},[_c('div',{staticClass:"container-white_box"},[_c('div',{staticClass:"white_box-top"},[_c('h3',{staticClass:"title-box"},[_vm._v(" 인구 통계 "),_c('span',{on:{"click":function($event){_vm.titleInfo = !_vm.titleInfo}}},[_c('img',{attrs:{"src":require("@/assets/notice-icon.svg"),"alt":"인포 아이콘"}})])]),(_vm.titleInfo)?_c('div',{staticClass:"title-box_info"},[_c('p',[_vm._v("최근 한달 기준의 성별, 연령별 비율입니다.")]),_c('span',{on:{"click":function($event){_vm.titleInfo = !_vm.titleInfo}}},[_c('img',{attrs:{"src":require("@/assets/home_remove_all_m.svg"),"alt":"닫기 아이콘"}})])]):_vm._e()]),(_vm.dataActive === false)?_c('div',{staticClass:"empty_component",staticStyle:{"color":"#d6dce2"}},[_vm._m(0)]):_vm._e(),(_vm.dataActive === true)?_c('div',{staticClass:"table_population"},[_c('div',{staticStyle:{"position":"relative"}},[_c('div',{staticClass:"table-wrap"},[(_vm.loading)?_c('div',{staticClass:"loading"},[_c('LoadingView')],1):_vm._e(),_c('table',{staticClass:"table_population-sex"},[_c('thead',[_vm._m(1),_c('tr',_vm._l((_vm.genderHeader),function(item){return _c('th',{key:item},[_vm._v(_vm._s(item))])}),0)]),(!_vm.errBox)?_c('tbody',_vm._l((_vm.genderTableItems),function(item,index){return _c('tr',{key:index},[_c('th',[_vm._v(_vm._s(item.keyword))]),(item.max !== 0)?_c('td',{class:{
                    value_min: item.male === item.min,
                    value_max: item.male === item.max,
                  }},[_vm._v(" "+_vm._s(item.male)+" % ")]):_c('td',[_vm._v("-")]),(item.max !== 0)?_c('td',{class:{
                    value_min: item.female === item.min,
                    value_max: item.female === item.max,
                  }},[_vm._v(" "+_vm._s(item.female)+" % ")]):_c('td',[_vm._v("-")])])}),0):_vm._e()]),_c('div',{staticClass:"table_bg"})]),(_vm.errBox)?_c('div',[_vm._m(2)]):_vm._e()]),_c('div',{staticStyle:{"position":"relative"}},[(_vm.loading)?_c('div',{staticClass:"loading"},[_c('LoadingView')],1):_vm._e(),_c('div',{staticClass:"table-wrap"},[_c('table',{staticClass:"table_population-total"},[_c('thead',[_vm._m(3),_c('tr',[_c('th',{staticClass:"table_bg_th"},[_vm._v("키워드")]),_vm._l((_vm.ageHeader),function(item){return _c('th',{key:item},[_vm._v(_vm._s(item))])})],2)]),(!_vm.errBox)?_c('tbody',_vm._l((_vm.ageTableItems),function(item,index){return _c('tr',{key:index},[_c('th',[_vm._v(_vm._s(item.keyword))]),(item.max !== 0)?_c('td',{class:{
                    value_min: item[10] == item.min,
                    value_max: item[10] == item.max,
                  }},[_vm._v(" "+_vm._s(item[10])+" % ")]):_c('td',[_vm._v("-")]),(item.max !== 0)?_c('td',{class:{
                    value_min: item[20] == item.min,
                    value_max: item[20] == item.max,
                  }},[_vm._v(" "+_vm._s(item[20])+" % ")]):_c('td',[_vm._v("-")]),(item.max !== 0)?_c('td',{class:{
                    value_min: item[30] == item.min,
                    value_max: item[30] == item.max,
                  }},[_vm._v(" "+_vm._s(item[30])+" % ")]):_c('td',[_vm._v("-")]),(item.max !== 0)?_c('td',{class:{
                    value_min: item[40] == item.min,
                    value_max: item[40] == item.max,
                  }},[_vm._v(" "+_vm._s(item[40])+" % ")]):_c('td',[_vm._v("-")]),(item.max !== 0)?_c('td',{class:{
                    value_min: item[50] == item.min,
                    value_max: item[50] == item.max,
                  }},[_vm._v(" "+_vm._s(item[50])+" % ")]):_c('td',[_vm._v("-")])])}),0):_vm._e()])]),(_vm.errBox)?_c('div',[_vm._m(4)]):_vm._e(),_c('div',{staticClass:"table_bg"})])]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"announce_icon_fill"}),_c('div',{staticClass:"empty_text"},[_vm._v("데이터가 존재하지 않습니다.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',{attrs:{"colspan":"3"}},[_vm._v("성별")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('article',{staticClass:"no_data"},[_c('div',{staticClass:"announce_icon_fill no_data_table_img"}),_c('div',{staticClass:"empty_text no_data_table"},[_vm._v(" 데이터 공급처 장애로 서비스가 지연되고 있습니다. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',{staticClass:"table_name_keyword"}),_c('th',{attrs:{"colspan":"5"}},[_vm._v("연령")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('article',{staticClass:"no_data"},[_c('div',{staticClass:"announce_icon_fill no_data_table_img"}),_c('div',{staticClass:"empty_text no_data_table"},[_vm._v(" 데이터 공급처 장애로 서비스가 지연되고 있습니다. ")])])
}]

export { render, staticRenderFns }