import axios from "axios";

// test url
// http://localhost:8080/?token=eyJwaWQiOjEsImNpZCI6MX0=
// https://dev.tingsearch.com/?token=eyJwaWQiOjEsImNpZCI6MX0=
// http://localhost:8080/payment/success?amount=4000

/**
 * @param {*} type : visit 방문 / sign 가입 / deal 결제
 * @param {*} info : object 유저정보
 */
export const SetPCookie = async (type, info) => {
  const URLSearch = new URLSearchParams(location.search);

  //쿠키 굽기!
  let token = URLSearch.get("ptoken");
  let amount = URLSearch.get("amount");
  let pay_id = URLSearch.get("pay_id");
  let active = await getCookie("t_partnerToken");

  let params = {
    type: type,
  };
  switch (type) {
    // 방문 추적
    case "visit": {
      params.refererUrl = document.referrer; // document.referrer;
      params.ptoken = token;
      break;
    }
    case "sign": {
      params.ptoken = decodeURIComponent(getCookieValue("t_partnerToken"));
      params.tingsearchUserId = info.me.id;
      params.tingsearchUserEmail = info.me.email;
      break;
    }
    case "deal": {
      let decodePath = decodeURIComponent(getCookieValue("t_partnerToken")); //토큰 가져와 디코드생성
      params.ptoken = decodePath;
      params.amount = Number(amount);
      params.tingsearchDealId = Number(pay_id);
      params.tingsearchUserId = info.me.id;
      params.tingsearchUserEmail = info.me.email;
      break;
    }
  }

  if (token !== null && active === false) {
    SetCookie("t_partnerToken", encodeURI(token), 30);
    axios.post(process.env.VUE_APP_API_PARTNERS_URL, params).catch((err) => {
      console.log(err.response.data.err);
    });
  }

  if (active === true && type !== "visit") {
    axios.post(process.env.VUE_APP_API_PARTNERS_URL, params).catch((err) => {
      console.log(err.response.data.err);
    });
  }
};

/**
 *
 * @param {*} name : string 쿠키 이름
 * @param {*} value : string 쿠키 이름에 넣어줄 값
 * @param {*} expiredays : number 날짜 1일 = 1
 */
export const SetCookie = (name, value, expiredays) => {
  let todayDate = new Date();
  todayDate.setDate(todayDate.getDate() + expiredays);

  document.cookie =
    name +
    "=" +
    escape(value) +
    "; path=/; expires=" +
    todayDate.toGMTString() +
    ";";
};

/**
 *
 * @param {*} cookieName : string 찾고싶은 cookeName을 입력
 * 쿠키 있음 : true
 * 쿠키 없음 : false
 */
export const getCookie = (cookieName) => {
  let cookiedata = document.cookie;
  if (cookiedata.indexOf(cookieName) < 0) {
    return false;
  } else {
    return true;
  }
};

/**
 *
 * @param {*} cookieName : 얻고싶은 cookie 값
 * @returns cookieToken
 */
export const getCookieValue = (cookieName) => {
  let cookiedata = document.cookie;

  return (
    cookiedata.match("(^|;)\\s*" + cookieName + "\\s*=\\s*([^;]+)")?.pop() || ""
  );
};
