<template>
  <div>
    <!-- web 전용 -->

    <div class="search_form-wrap searchBar">
      <!-- 리스트 -->
      <div
        :style="{ display: autoList === true ? 'block' : 'none' }"
        class="autoComplate_web"
      >
        <ul v-if="comboboxItems?.length > 0">
          <li
            v-for="(item, index) in comboboxItems"
            :key="index"
            @click="addSearchItem('search', item)"
            style="cursor: pointer"
          >
            <div style="display: flex; justify-content: space-between">
              <div>
                {{ item }}
              </div>
            </div>
          </li>
        </ul>
        <!-- <div v-else>키워드를 입력해주세요</div> -->
      </div>

      <!-- 검색창 -->

      <div class="web_search_wrap">
        <div class="icon_search">
          <span @click="addSearchItem('webSearch', inputKeyword)"></span>
        </div>
        <!-- <div class="web_search_Item">
          <ul>
            <li v-for="(item, index) in keywordArr" :key="index">
              <v-chip style="background: transparent" color="transparent">
                <p class="search_item_txt">
                  {{ item }}
                </p>
                <v-icon
                  @click="removeKeyword(index)"
                  style="font-size: 16px; color: #bac0c6"
                >
                  mdi-close</v-icon
                >
              </v-chip>
            </li>
          </ul>
        </div> -->
        <div class="web_search_input">
          <input
            v-model="inputKeyword"
            @keydown.tab="addSearchItem('webSearch', inputKeyword)"
            @keydown.enter="addSearchItem('webSearch', inputKeyword)"
            @keyup="searchInputBoxValue('web')"
            type="search"
            id="webKeyword"
            autocomplete="off"
            maxlength="20"
            placeholder="최대 20개까지 복수키워드 분석이 가능합니다. 키워드 입력 후 ‘Tab’ 키를 누르시면 구분됩니다."
          />
          <div v-if="inputKeyword !== ''" class="removeKeywordAll">
            <div
              @click="
                inputKeyword = '';
                autoList = false;
              "
            >
              <img src="@/assets/img/common/delete-btn.svg" alt="" />
            </div>
          </div>
        </div>
      </div>

      <div
        :style="{ display: autoList === true ? 'block' : 'none' }"
        class="bg"
        @click="autoList = false"
      ></div>
    </div>

    <div
      class="web_search_Item searchBar"
      v-if="keywordArr.length > 0"
      :style="{
        height: showKeyword ? 'auto' : '42px',
      }"
    >
      <ul>
        <li v-for="(item, index) in keywordArr" :key="index">
          <button>
            <p class="search_item_txt"># {{ item }}</p>
            <img
              @click="removeKeyword(index)"
              src="@/assets/img/common/icon_chip_close.svg"
              alt=""
            />
          </button>
        </li>
        <!-- <li class="removeAll" @click="removeKeywordAll">
          <button>
            <img src="@/assets/img/common/icon_remove_all.svg" alt="" />
          </button>
        </li> -->
      </ul>
      <div style="display: flex">
        <div class="removeAll" @click="removeKeywordAll">
          <button style="width: 42px; padding: 12px">
            <img
              style="display: block"
              src="@/assets/img/common/icon_remove_all.svg"
              alt=""
            />
          </button>
        </div>
        <div v-if="showBtnActive" style="margin-left: 8px">
          <button @click="showKeyword = !showKeyword">
            <img
              :style="{
                transform: showKeyword ? 'rotate(180deg)' : 'rotate(0)',
              }"
              src="@/assets/img/common/slide_btn.svg"
              alt=""
            />
            <p v-if="!showKeyword">펴기</p>
            <p v-else>접기</p>
          </button>
        </div>
      </div>
    </div>

    <!-- mobile 전용 -->

    <div class="search_form-wrap" id="mo_searchBar">
      <div
        :style="{ display: autoList === true ? 'block' : 'none' }"
        @focus="autoList = true"
        v-if="autoList"
        class="autoComplate"
      >
        <ul style="top: 0; padding: 0" v-if="comboboxItems?.length > 0">
          <li
            v-for="(item, index) in comboboxItems"
            :key="index"
            @click="addSearchItem('search', item)"
            style="cursor: pointer"
          >
            <div style="display: flex; justify-content: space-between">
              <div>
                {{ item }}
              </div>
            </div>
          </li>
        </ul>
        <!-- <div v-else>키워드를 입력해주세요</div> -->
      </div>

      <!-- 검색창 -->

      <span class="small_search_icon"></span>
      <input
        v-model="inputKeyword"
        @keydown.enter="addSearchItem('moSearch', inputKeyword)"
        @keyup="searchInputBoxValue('mobile')"
        type="search"
        id="mobileKeyword"
        autocomplete="off"
        placeholder="최대 20개까지 복수키워드 분석가능"
        maxlength="20"
      />
      <div
        v-if="inputKeyword !== ''"
        class="removeKeywordAll"
        style="z-index: 2"
      >
        <button
          @click="
            inputKeyword = '';
            autoList = false;
          "
        >
          <img src="@/assets/img/common/delete-btn.svg" alt="" />
        </button>
      </div>

      <div v-if="buttonActive" @click="setNewsKeyword">
        <button class="blue_btn">키워드 등록</button>
      </div>

      <!-- 검색된 리스트 -->
      <div
        :style="{
          height: showKeywordMo ? 'auto' : showBtnActiveMo ? '72px' : 'auto',
        }"
        style="overflow: hidden; margin-top: 16px"
        v-if="keywordArr.length > 0"
      >
        <div class="chip_box">
          <div
            style="margin: 0 4px 4px 0"
            class="removeAll"
            @click="removeKeywordAll"
          >
            <button>
              <img src="@/assets/img/common/icon_remove_all_m.svg" alt="" />
            </button>
          </div>
          <v-chip
            v-for="(item, index) in keywordArr"
            :key="index"
            style="
              border: solid 1px #e4e9f0;
              box-sizing: border-box;
              margin: 0 4px 4px 0;
              padding: 9px 7px 9px 12px;
              height: 34px;
            "
            color="#f7f9fb"
          >
            <p style="font-size: 12px; line-height: 34px">
              {{ item }}
            </p>
            <v-icon
              @click="removeKeyword(index)"
              style="
                font-size: 16px;
                color: #bac0c6;
                margin-left: 10px;
                line-height: 34px;
                margin-bottom: 0;
              "
            >
              mdi-close</v-icon
            >
          </v-chip>
        </div>
      </div>
      <article>
        <div
          v-if="showBtnActiveMo"
          :style="{ marginTop: showKeywordMo ? '14px' : '16px' }"
        >
          <button
            @click="showKeywordMo = !showKeywordMo"
            style="
              display: flex;
              margin: 0 auto;
              font-size: 14px;
              font-weight: bold;
            "
          >
            <p v-if="!showKeywordMo">펴기</p>
            <p v-else>접기</p>
            <img
              :style="{
                transform: showKeywordMo ? 'rotate(180deg)' : 'rotate(0)',
              }"
              src="@/assets/img/common/slide_btn.svg"
              alt=""
            />
          </button>
        </div>
      </article>
    </div>
    <div v-if="autoList" class="bg" @click="autoList = false"></div>
  </div>
</template>
<script>
  import { mapGetters } from "vuex";
  import { axiosService } from "@/api";

  export default {
    name: "searchBar",
    props: ["buttonActive"],
    data() {
      return {
        label:
          "최대 20개까지 복수키워드 분석이 가능합니다. 키워드 입력 후 ‘Tab’ 키를 누르시면 구분됩니다.", //keyword 여부에 따라 텍스트 생성
        newObj: {},
        windowWidth: 0,

        keywordArr: [], //keyword
        comboboxItems: [],
        autoList: false,

        inputKeyword: "",
        showKeyword: false,
        showKeywordMo: false,
        showBtnActive: false,
        showBtnActiveMo: false,
      };
    },
    mounted() {
      //리사이징
      this.$nextTick(() => {
        this.setBtnActive();
        window.addEventListener("resize", this.onResize);
      });
      //키워드 초기값 설정
      if (typeof this.$route.query.keywords === "string") {
        this.keywordArr = this.$route.query.keywords
          ? [this.$route.query.keywords]
          : [];
      } else {
        this.keywordArr = this.$route.query.keywords
          ? this.$route.query.keywords
          : [];
      }

      if (this.comboboxItems?.length > 0) {
        this.label = "";
      }
    },
    computed: {
      ...mapGetters({
        keywordState: "keyword/keywordState",
      }),
    },
    watch: {
      keywordArr(val) {
        if (val.length > 0) {
          this.label = "";
        }

        //키워드를 20개 이상 입력한 경우
        if (val.length > 20) {
          this.$store.commit(
            "controller/setMsg",
            "키워드분석은 최대 20개까지 가능합니다"
          );
          this.$nextTick(() => this.keywordArr.pop());
        }

        //키워드 변경시 route가 실행되면서 총 2번 실행됨
        // this.$store.commit("keyword/setKeyword", this.keywordArr);
      },
      inputKeyword(v) {
        this.$store.commit("keyword/setMobileKeyword", v);
      },
    },

    methods: {
      // 뉴스추가
      setNewsKeyword() {
        this.$emit("setNewsKeyword");
      },
      removeKeywordAll() {
        this.$store.commit("keyword/setKeyword", []);
      },
      activate() {
        setTimeout(() => (this.autoList = false), 100);
      },

      onResize() {
        this.setBtnActive();
        this.windowWidth = window.innerWidth;
      },
      setBtnActive() {
        if (document.querySelector(".web_search_Item ul")?.clientHeight) {
          this.showBtnActive =
            document.querySelector(".web_search_Item ul")?.clientHeight > 50
              ? true
              : false;
          this.showBtnActiveMo =
            document.querySelector(".chip_box")?.clientHeight > 80
              ? true
              : false;
        }
      },
      //웹 인풋박스에 텍스트가 입력되면 실행되어 '연관검색어'를 받아옵니다
      searchInputBoxValue(type) {
        let searchTxt = "";

        if (type === "mobile") {
          searchTxt = document.getElementById("mobileKeyword").value;
        } else {
          searchTxt = document.getElementById("webKeyword").value;
        }

        if (searchTxt.length > 19) {
          this.$store.commit(
            "controller/setMsg",
            "키워드는 20자까지 검색 가능합니다"
          );
          return;
        }

        if (searchTxt !== "") {
          this.$nextTick(() => {
            this.autoSetKeyword(searchTxt);
          });
        } else {
          this.autoList = false;
          this.comboboxItems = [];
        }
      },

      //연관검색어를 호출해 mo/web 리스트에 넣어줍니다
      autoSetKeyword(val) {
        axiosService(`v2/search/keyword/related/${val}`)
          .then((res) => {
            this.autoList = true;
            this.comboboxItems = [];
            this.comboboxItems = res.data.autoComplete;
            if (res.data.autoComplete.length === 0) {
              this.autoList = false;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },

      //web버전에서는 뒤로가기를 누를경우 마지막 키워드가 삭제됩니다
      removeSearchItem(e) {
        let value = document.getElementById("webKeyword").value;
        if (value === "" && e.keyCode === 8) {
          this.$nextTick(() => this.keywordArr.pop());
          let reArr = [...this.keywordArr];
          // this.keywordArr = [];
          this.keywordArr = [...reArr];

          this.$store.commit("keyword/setKeyword", this.keywordArr);
        }
      },

      //검색된 키워드 중 특정항목만을 삭제합니다
      removeKeyword(index) {
        this.$nextTick(() => this.keywordArr.splice(index, 1));
        let reArr = [...this.keywordArr];
        // this.keywordArr = [];
        this.keywordArr = [...reArr];
        this.$store.commit("keyword/setKeyword", this.keywordArr);
      },

      //이벤트에 따라 키워드를 검색해줍니다
      addSearchItem(e, item) {
        if (item.length > 20) {
          this.$store.commit(
            "controller/setMsg",
            "키워드는 20자까지 검색 가능합니다"
          );
          return;
        }
        if (this.keywordArr.length === 20) {
          this.$store.commit(
            "controller/setMsg",
            "키워드는 20개까지 검색 가능합니다"
          );
          return;
        }
        //click
        if (e === "search") {
          let dubble = false;
          this.keywordArr.map((keywordArr) => {
            if (keywordArr === item) {
              dubble = true;
              this.inputKeyword = "";
              return;
            }
          });
          if (dubble === false) {
            let value = item;
            let reArr = [...this.keywordArr];

            reArr.push(value);
            // this.keywordArr = [];
            this.keywordArr = [...reArr];
          } else {
            this.autoList = false;
          }
        }

        //web
        if (e === "webSearch") {
          let dubble = false;
          let value = document.getElementById("webKeyword").value;

          if (value === "") {
            return;
          } else {
            this.keywordArr.map((keywordArr) => {
              if (keywordArr === value) {
                dubble = true;
                this.inputKeyword = "";
                this.autoList = false;
                return;
              }
            });

            if (dubble === false) {
              let reArr = [...this.keywordArr];
              reArr.push(value);
              // this.keywordArr = [];
              this.keywordArr = [...reArr];
            }
          }
        }

        //mobile
        if (e === "moSearch") {
          let dubble = false;
          let value = document.getElementById("mobileKeyword").value;

          if (value === "") {
            return;
          } else {
            this.keywordArr.map((keywordArr) => {
              if (keywordArr === value) {
                dubble = true;
                this.inputKeyword = "";
                this.autoList = false;
                return;
              }
            });

            if (dubble === false) {
              let reArr = [...this.keywordArr];
              reArr.push(value);
              // this.keywordArr = [];
              this.keywordArr = [...reArr];
            }
          }
        }
        this.$store.commit("keyword/setKeyword", this.keywordArr);
      },
    },
  };
</script>

<style lang="css" scoped>
  .searchBar {
    position: relative;
    display: flex;
    z-index: 9;
    margin-top: 8px;
  }
  .searchBar .v-text-field.v-select--is-menu-active .v-label {
    display: none;
  }
  /* #searchBar {
    display: none;
  } */
  .v-application--is-ltr #searchBar .v-text-field .v-label.theme--light {
    font-size: 14px;
    color: #d6dce2;
    top: 4px;
  }
  .theme--light.v-chip--active:before {
    background-color: #f7f9fb !important;
    border: solid 1px #e4e9f0;
    opacity: 1 !important;
  }
  #mo_searchBar {
    display: none;
    width: 100%;
    position: relative;
    z-index: 9;
  }
  #mo_searchBar input {
    width: 100%;
    height: 42px;
    border-radius: 21px;
    padding: 11px 16px 11px 50px;
    font-size: 14px;
    border: solid 2px #111;
    box-shadow: 0 6px 10px rgba(0, 65, 162, 0.07);
    box-sizing: border-box;
  }
  #mo_searchBar .small_search_icon {
    content: "";
    position: absolute;
    width: 24px;
    height: 24px;
    background-image: url(@/assets/img/mobile/small_search_icon.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    left: 16px;
    top: 9px;
  }
  #mo_searchBar input::placeholder {
    font-size: 14px;
    color: #d6dce2;
  }
  .chip_box {
    width: 100%;
    display: flex;
    overflow: hidden;
    flex-wrap: wrap;
    height: max-content;
  }
  .v-application p {
    margin-bottom: 0;
  }

  .autoComplate_web {
    position: absolute;
    top: 45px;
    left: 0;
    z-index: 888;
    background-color: #fff;
    width: 100%;
    box-shadow: 0 20px 20px 0 rgba(0, 65, 162, 0.03);
    border-radius: 8px;
    overflow: hidden;
    padding: 8px 10px 8px 0;
  }
  .autoComplate_web ul::-webkit-scrollbar {
    width: 4px;
    background-color: #eaeef3;
    border-radius: 2px;
  }
  .autoComplate_web ul::-webkit-scrollbar-thumb {
    width: 4px;
    background-color: #3943ff;
  }
  .autoComplate_web li:hover {
    background-color: #f7fbff;
    cursor: pointer;
  }
  .autoComplate {
    position: absolute;
    top: 50px;
    left: 0;
    z-index: 888;
    background-color: #fff;
    width: 100%;
    box-shadow: 0 20px 20px 0 rgba(0, 65, 162, 0.03);
    padding: 8px;
    border-radius: 8px;
    overflow: hidden;
  }
  .autoComplate ul,
  .autoComplate_web ul {
    margin-bottom: 0;
    height: 40vh;
    overflow: auto;
    position: relative;
    border-radius: 8px;
    z-index: 3;
  }
  .autoComplate ul {
    box-sizing: border-box;
  }
  .autoComplate ul::-webkit-scrollbar {
    width: 4px;
    background-color: #eaeef3;
    border-radius: 2px;
  }
  .autoComplate ul::-webkit-scrollbar-thumb {
    width: 4px;
    background-color: #3943ff;
  }
  .autoComplate ul li {
    padding: 12px 0px 12px 16px;
    box-sizing: border-box;
  }
  .autoComplate_web ul {
    height: 400px;
  }
  .autoComplate li,
  .autoComplate_web li {
    padding: 10px 16px;
  }
  .autoComplate_web li {
    padding: 12px 44px;
    box-sizing: border-box;
    font-size: 14px;
  }
  .autoComplate > div,
  .autoComplate_web > div {
    text-align: center;
    color: #d6dce2;
    height: 40px;
    line-height: 40px;
  }
  .autoComplate_web > div {
    text-align: left;
    padding-left: 50px;
    box-sizing: border-box;
    height: 40px;
    line-height: 40px;
  }

  .bg {
    position: fixed;
    z-index: 8;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
  .web_search_input {
    width: 100%;
    height: 34px;
  }
  .web_search_input input {
    width: 100%;
  }
  .web_search_wrap {
    display: flex;
    justify-content: left;
    align-items: center;
    z-index: 9;
    width: 100%;
  }
  .web_search_Item {
    position: relative;
    overflow: hidden;
    height: 45px;
    z-index: 0;
    transition: all 0.3s ease;
    padding-left: 40px;
  }
  .web_search_Item ul {
    margin-bottom: 0;
    width: calc(100% - 140px);
    height: max-content;
    display: flex;
    flex-wrap: wrap;
  }
  .web_search_Item > div {
    position: absolute;
    right: 0;
    top: 0;
  }
  .web_search_Item > div button {
    width: 81px;
    height: 42px;
    background-color: #f1f4f8;
    border-radius: 21px;
    box-sizing: border-box;
    padding: 0px 16px 0px 12px;
    line-height: 37px;
  }
  .web_search_Item > div button p {
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    color: #111;
    margin-left: 4px;
    height: 20px;
    width: 25px;
    box-sizing: border-box;
  }

  .web_search_Item li {
    display: block;
    color: #111;
    font-weight: normal;
    border: solid 1px #e4e9f0;
    z-index: 1;
    border-radius: 21px;
    margin: 0 8px 8px 0;
    height: 42px;
    padding: 0px 12px 0px 16px;
    box-sizing: border-box;
    line-height: 37px;
  }
  .web_search_Item li.removeAll {
    background-color: #f1f4f8;
    padding: 12px;
    border: none;
  }

  .chip_box .removeAll {
    width: 34px;
    height: 34px;
    border-radius: 21px;
    background-color: #f1f4f8;
    padding: 12px;
    border: none;
    position: relative;
  }
  .chip_box .removeAll img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .web_search_Item p.search_item_txt {
    color: #111;
    font-weight: normal;
    border-color: #e4e9f0;
    font-size: 14px;
    margin: 0 10px 0 0;
  }
  .web_search_Item p {
    display: inline-block;
  }
  .web_search_Item li.removeAll img {
    display: block;
  }

  .removeKeywordAll {
    position: absolute;
    right: 16px;
    cursor: pointer;
    top: 8px;
    top: 50%;
    transform: translateY(-50%);

    transition: all 0.2s ease;
  }
  /* .removeKeywordAll div:last-child:after {
    content: "전체 키워드 삭제";
    transition: all 0.2s ease;
    padding: 4px 4px;
    border-radius: 4px;
    width: 130px;
    font-size: 14px;
    text-align: center;
    left: -100px;
    position: absolute;
    top: 28px;
    background-color: rgba(0, 0, 0, 0.75);
    color: white;
    opacity: 0;
  } */
  /* .removeKeywordAll:hover::after {
    opacity: 1;
  } */

  /* 피드 */
  .blue_btn {
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    border-radius: 21px;
    background: #3943ff;
    width: 116px;
    height: 42px;
    line-height: 40px;
  }
  @media screen and (max-width: 1023px) {
    .searchBar {
      display: none;
    }
    #mo_searchBar {
      display: block;
    }
    .removeKeywordAll {
      top: 8px;
      transform: unset;
    }
    .removeKeywordAll::after {
      display: none;
    }
    .blue_btn {
      width: 100%;
      margin-top: 15px;
    }
  }
</style>
