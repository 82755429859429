<template>
  <footer :style="{ position: fixed === false ? 'fixed' : 'relative' }">
    <p>
      <span>주식회사 팅코 </span> <span class="pc"> &nbsp;</span>
      <br class="mo" />
      대표자 : 김보경 &nbsp; &nbsp;통신판매업신고 : 제 2022-서울서초-1940 호
      &nbsp; &nbsp;사업자번호 : 657-86-01866 <br class="mo" />
      &nbsp; &nbsp;주소 : 서울특별시 서초구 서초중앙로18길 43, 4층(서초동,
      율전빌딩)&nbsp; &nbsp;<br class="mo" />
      고객센터 : 070-4128-4220
      <br />
      ©Tingsearch. All Rights Reserved.
    </p>
    <!-- <p>Copyright © <span>Tinco.</span> All Rights Reserved.</p> -->
  </footer>
</template>

<script>
  import { mapGetters } from "vuex";
  export default {
    name: "FooterView",
    data() {
      return {
        screenHeight: 0,
        height: 0,
        fixed: true,
        routeName: false,
      };
    },
    computed: {
      ...mapGetters({
        footerActive: "keyword/footerActiveState",
      }),
    },
    watch: {
      footerActive() {
        this.screenHeight = window.innerHeight;
        this.height = document.getElementsByClassName("wrap")[0]?.clientHeight;

        if (this.screenHeight <= this.height) {
          this.fixed = true;
        } else {
          this.fixed = false;
        }
      },
      $route(to, from) {
        this.routeName = to.name;
        if (to.name !== from.name) {
          this.$store.commit("keyword/setFooter", false);
          this.screenHeight = window.innerHeight;
          this.height =
            document.getElementsByClassName("section_wrap")[0]?.clientHeight;
        }
        if (this.screenHeight <= this.height) {
          this.fixed = true;
        } else {
          this.fixed = false;
        }
      },
      height(v) {
        this.screenHeight = window.innerHeight;

        if (this.screenHeight <= v) {
          this.fixed = true;
        } else {
          this.fixed = false;
        }
      },
    },
    mounted() {
      if (location.pathname === "/keyword/manager") {
        this.routeName = true;
      }
      setTimeout(() => {
        this.screenHeight = window.innerHeight;
        this.height = document.getElementsByClassName("wrap")[0].clientHeight;
        if (this.screenHeight <= this.height && this.routeName !== true) {
          this.fixed = true;
        } else {
          this.fixed = false;
        }
      }, 5000);

      this.$nextTick(() => {
        window.addEventListener("resize", this.onResize);
      });
    },
    methods: {
      onResize() {
        this.screenHeight = window.innerHeight;
        this.height = document.getElementsByClassName("wrap")[0].clientHeight;

        if (this.screenHeight < this.height) {
          this.fixed = true;
        } else {
          this.fixed = false;
        }
      },
    },
  };
</script>
<style lang="css" scoped>
  footer {
    background-color: #fff;
    height: 103px;
    padding: 32px 40px 31px 220px;
    text-align: left;
    width: 100%;
    box-shadow: 0px -10px 20px rgba(0, 65, 162, 0.03);
    box-sizing: border-box;
    font-size: 12px;
    color: #acb2b9;
    z-index: 0;
    bottom: 0;
    z-index: 98;
  }
  footer p {
    color: #acb2b9;
    margin-bottom: 0;
  }
  footer span {
    font-weight: bold;
    color: #acb2b9;
  }
  .mo {
    display: none;
  }
  .pc {
    display: inline-block;
  }
  @media all and (max-width: 1024px) {
    footer {
      padding: 24px 32px;
      text-align: center;
      height: auto;
    }
    .mo {
      display: block;
    }
    .pc {
      display: none;
    }
    footer p {
      font-size: 11px;
      line-height: 18px;
    }
  }
</style>
