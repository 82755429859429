<template>
  <div>
    <!-- default -->

    <article v-if="defult" :class="{ default: active }">
      <div class="left_box">
        <span>
          <img
            src="@/assets/img/common/toast/notice-icon.svg"
            alt="안내 아이콘"
          />
        </span>
        <p v-html="text" />
      </div>
      <div @click="close">
        <img
          src="@/assets/img/common/toast/close_btn_icon.svg"
          alt="안내문 닫기"
        />
      </div>
    </article>

    <!-- err -->

    <article v-else :class="{ error: active }">
      <div class="left_box">
        <span>
          <img
            src="@/assets/img/common/toast/notice-icon.svg"
            alt="안내 아이콘"
          />
        </span>
        <p v-html="text" />
      </div>
      <div @click="close">
        <img
          src="@/assets/img/common/toast/close_btn_icon.svg"
          alt="안내문 닫기"
        />
      </div>
    </article>
    <!-- <v-snackbar v-model="snackbar">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          닫기
        </v-btn>
      </template>
    </v-snackbar> -->
  </div>
</template>
<script>
  import { mapGetters } from "vuex";
  export default {
    name: "AlertBar",
    data() {
      return {
        multiLine: true,
        snackbar: false,
        text: "",
        defult: false,
      };
    },
    computed: {
      ...mapGetters({
        msg: "controller/activeMessage",
        active: "controller/active",
        type: "controller/type",
      }),
    },
    watch: {
      msg(v) {
        this.text = v;
      },
      active(v) {
        this.snackbar = v;
      },
      type(v) {
        if (v === "error") {
          this.defult = false;
        } else if (v === "defult") {
          this.defult = true;
        }
      },
    },
    methods: {
      close() {
        this.$store.commit("controller/setCloseMsg");
      },
    },
  };
</script>
<style lang="css" scoped>
  article {
    position: fixed;
    z-index: 999999;
    left: 50%;
    top: -90px;
    border-radius: 8px;
    overflow: hidden;
    padding: 16px 20px;
    width: calc(100% - 72px);
    max-width: 336px;
    height: auto;
    min-height: 52px;
    color: white;
    box-sizing: border-box;
    transform: translateX(-50%);
    justify-content: space-between;
    transition: all 0.3s ease-in-out;
    display: flex !important;
    opacity: 0;
  }
  article .left_box {
    display: flex;
    align-items: center;
  }
  article .left_box span {
    margin-right: 8px;
  }

  article p {
    font-size: 14px;
    font-weight: bold;
    color: white;
    margin-bottom: 0;
  }
  article.default {
    background-color: rgb(17, 17, 17, 0.8);
    top: 40px;
    opacity: 1;
  }
  article.error {
    background-color: rgb(229, 31, 54, 0.8);
    top: 40px;
    opacity: 1;
  }
</style>
