<template>
  <!-- table: 표 -->
  <div class="white_box" style="background: #fff">
    <div class="container-white_box">
      <div class="white_box-top">
        <h3 class="title-box">표</h3>

        <button
          class="box_btn manage_btn"
          @click="registerKeywords('all')"
          v-if="dataActive === true"
        >
          <!-- <img
            src="@/assets/img/common/icon_common-add.svg"
            alt="전체관리등록"
            class="remove_btn"
          /> -->
          전체관리등록
        </button>
      </div>
      <div
        class="empty_component"
        v-if="dataActive === false"
        style="color: #d6dce2"
      >
        <div>
          <div class="announce_icon_fill"></div>
          <div class="empty_text">데이터가 존재하지 않습니다.</div>
        </div>
      </div>
      <a-spin :spinning="loading" v-else>
        <div class="table-wrap">
          <table class="table">
            <thead>
              <tr>
                <th class="table_date_cell table_border"></th>

                <th
                  class="table_date_cell_contentItems"
                  v-for="(item, index) in keywordsNames"
                  :key="index"
                >
                  {{ item.keyword }}
                </th>
              </tr>
              <tr>
                <th class="table_date_cell"></th>
                <th v-for="(item, index) in keywordsNames" :key="index">
                  <button @click="showRelateModal(item)">연관</button>
                  <button
                    @click="registerKeywords('alone', index)"
                    :class="{ active: item.isManaged }"
                    style="padding: 7px 11px 8px 10px"
                  >
                    <v-icon style="font-size: 14px; color: #bdc2c8"
                      >mdi-plus</v-icon
                    >
                    관리
                  </button>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(dateItem, dateIndex) in tableList[0]?.search"
                :key="dateIndex"
              >
                <th>
                  {{
                    dateItem.date === "Sun"
                      ? "일"
                      : dateItem.date === "Mon"
                      ? "월"
                      : dateItem.date === "Tue"
                      ? "화"
                      : dateItem.date === "Wed"
                      ? "수"
                      : dateItem.date === "Thu"
                      ? "목"
                      : dateItem.date === "Fri"
                      ? "금"
                      : dateItem.date === "Sat"
                      ? "토"
                      : dateItem.date
                  }}
                </th>
                <td v-for="(item, index) in tableList" :key="index">
                  {{
                    item.search[dateIndex] &&
                    item.search[dateIndex].amount.toLocaleString()
                  }}
                </td>
              </tr>
            </tbody>
          </table>
          <div class="table_bg"></div>
        </div>
      </a-spin>
    </div>
  </div>
</template>
<script>
  import { axiosService } from "@/api/index";
  export default {
    name: "placeTable",
    props: ["data", "loading", "type"],
    data() {
      return {
        tableList: [],
        keywordsNames: [],
        dataActive: false,
        dialog: false,

        keywordType: null,
        value: null,
      };
    },
    watch: {
      data(v) {
        if (!v) {
          this.dataActive = false;
        } else {
          this.dataActive = true;
          if (this.type !== "demo") {
            this.getData();
          } else {
            //혹시몰라 분기점
            this.getData();
          }
        }
      },
    },
    mounted() {
      if (this.data.length > 0) {
        this.dataActive = true;
        if (this.type !== "demo") {
          this.getData();
        } else {
          //혹시몰라 분기점
          this.getData();
        }
      } else {
        this.dataActive = false;
      }
    },
    methods: {
      async getData() {
        //y축 필드 (시간)
        this.tableList = [];
        this.keywordsNames = this.data.data.map((d) => ({
          keyword: d.keyword,
          isManaged: d.isManaged,
        }));
        this.data.data.map((item) => {
          let data = {
            ...item,
          };
          this.tableList.push(data);
        });
      },
      showRelateModal(item) {
        this.$emit("showRelateModal", item);
      },

      confirmAgree() {
        let fd = new FormData();
        if (this.keywordType === "all") {
          let stateKeyword = this.$store.state.keyword.keywords;
          for (let i = 0; stateKeyword.length > i; i++) {
            fd.append("keywords[]", stateKeyword[i]);
          }
        } else {
          // let result = confirm("해당 키워드를 관리목록에 담으시겠습니까?");
          // if (result) {
          let stateKeyword = this.$store.state.keyword.keywords;
          fd.append("keywords[]", stateKeyword[this.value]);
          // }
        }
      },
      //키워드 관리목록에 저장
      registerKeywords(type, value) {
        // if (this.$route.name === "GuideHomeView") {
        //   this.$store.commit("controller/setMsg", "구독 후 사용이 가능합니다");
        // } else

        if (this.$route.name === "DemoView") {
          this.$emit("setMsg");
        } else {
          let fd = new FormData();
          this.keywordType = type;
          if (type === "all") {
            let resultAll = confirm("키워드 전체를 관리목록에 담으시겠습니까?");

            if (resultAll) {
              let stateKeyword = this.$store.state.keyword.keywords;
              for (let i = 0; stateKeyword.length > i; i++) {
                fd.append("keywords[]", stateKeyword[i]);
              }
            } else {
              return;
            }
          } else if (type === "alone") {
            let result = confirm("해당 키워드를 관리목록에 담으시겠습니까?");
            if (result) {
              let stateKeyword = this.$store.state.keyword.keywords;
              fd.append("keywords[]", stateKeyword[value]);
            } else {
              return;
            }
          }

          //키워드 담기 실행
          axiosService.post("v2/keyword", fd).then(() => {
            this.$store.commit(
              "controller/setMsg",
              "키워드 관리목록에 성공적으로 담겼습니다!"
            );
            if (type === "all") {
              for (let i = 0; this.keywordsNames.length > i; i++) {
                this.keywordsNames[i].isManaged = true;
              }
            } else if (type === "alone") {
              this.keywordsNames[value].isManaged = true;
            }
          });
        }
      },
    },
  };
</script>
<style lang="css" scoped>
  .white_box-top {
    flex-direction: unset;
  }
  .table {
    width: auto;
  }
  .table-wrap {
    height: 400px;
    position: initial;
  }
  .table_bg {
    width: calc(100% - 4px);
  }
  .active {
    background-color: #f1f4f8;
    color: #bdc2c8;
    pointer-events: none;
  }
  @media screen and (max-width: 1920px) {
    /* .table {
      width: 100%;
    } */
  }
  @media screen and (max-width: 1440px) {
    .white_box-top {
      flex-direction: column;
    }
  }
  @media screen and (max-width: 820px) {
    .table_date_cell_contentItems {
      width: 140px !important;
    }
  }
</style>
