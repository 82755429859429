import axios from "axios";

import { setInterceptors } from "./interceptors";

function createAxiosService() {
  const axiosService = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
  });

  return setInterceptors(axiosService);
}

export const axiosService = createAxiosService();
