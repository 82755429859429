<template>
  <div>
    <section>
      <div v-if="contectModal === true" class="modal">
        <div class="modal_con">
          <div>
            <div class="modal_header">
              <h2>문의하기</h2>
              <div class="modal_close_btn" @click="closeModal">
                <img
                  src="@/assets/img/modal_close.svg"
                  alt="모달 닫기 아이콘"
                />
              </div>
            </div>
            <form action="submit" @submit="submit">
              <div>
                <label for="category">문의유형</label>
                <select name="category" id="category">
                  <option
                    :value="item.value"
                    v-for="(item, index) in submitType"
                    :key="index"
                  >
                    {{ item.text }}
                  </option>
                </select>
              </div>
              <div>
                <label for="content">문의내용</label>
                <textarea
                  name="content"
                  id="content"
                  cols="30"
                  rows="5"
                  placeholder="문의내용을 입력해 주세요 "
                ></textarea>
              </div>
            </form>
            <div style="text-align: center">
              <button @click="submit">제출</button>
            </div>
          </div>
        </div>
        <div class="modal_bg" @click="closeModal"></div>
      </div>
    </section>
    <section id="floating" v-if="floatingActive">
      <article
        v-if="floatingInfo"
        :style="{ marginBottom: $isDemo === true ? '30px' : '0' }"
      >
        <p>
          팅서치에 대해 궁금하신가요? <br class="mo" />
          언제든지 편하게 문의주세요!
        </p>
        <img @click="closePopup" src="@/assets/img/floating_close.svg" alt="" />
      </article>
      <button :style="{ marginBottom: $isDemo === true ? '30px' : '0' }">
        <a href="http://pf.kakao.com/_xjxefzxj/chat" target="_blank">
          <img
            :style="{
              height:
                $route.name === 'HomeWeb'
                  ? '75%'
                  : $route.name === 'HomeMobile'
                  ? '75%'
                  : 'auto',
            }"
            src="@/assets/kakao_icon.svg"
            alt=""
        /></a>
      </button>
    </section>
  </div>
</template>

<script>
  export default {
    name: "FloatingView",
    data() {
      return {
        contectModal: false,
        floatingActive: false,
        floatingInfo: true,
        submitType: [
          {
            text: "문의유형을 선택해 주세요",
            value: "",
          },
          {
            text: "1",
            value: "1",
          },
          {
            text: "2",
            value: "2",
          },
        ],
      };
    },
    mounted() {
      let route = this.$route;
      if (
        route.name === "HomeView" ||
        route.name === "KeywordManage" ||
        route.name === "GroupManage" ||
        route.name === "FeedsView" ||
        route.name === "SaveView" ||
        route.name === "MypageView" ||
        route.name === "MemberInfo" ||
        route.name === "HomeWeb" ||
        route.name === "HomeMobile" ||
        this.$isDemo === true
      ) {
        this.floatingActive = true;
      } else {
        this.floatingActive = false;
      }

      this.getCookie();
    },
    watch: {
      $route(route) {
        if (
          route.name === "HomeView" ||
          route.name === "KeywordManage" ||
          route.name === "GroupManage" ||
          route.name === "FeedsView" ||
          route.name === "SaveView" ||
          route.name === "MypageView" ||
          route.name === "MemberInfo" ||
          route.name === "HomeWeb" ||
          route.name === "HomeMobile" ||
          this.$isDemo === true
        ) {
          this.floatingActive = true;
        } else {
          this.floatingActive = false;
        }
      },
    },
    methods: {
      // openModal() {
      //   this.contectModal = true;
      // },
      closeModal() {
        this.contectModal = false;
      },
      submit() {
        this.closeModal();
      },
      closePopup() {
        // if (this.contectModal === false) {
        this.setCookie("todayCookie", "done", 1);
        // }
        this.floatingInfo = false;
      },
      getCookie() {
        let cookiedata = document.cookie;
        if (cookiedata.indexOf("todayCookie=done") < 0) {
          this.floatingInfo = true;
        } else {
          this.floatingInfo = false;
        }
      },
      setCookie(name, value, expiredays) {
        //하루
        let todayDate = new Date();
        todayDate.setDate(todayDate.getDate() + expiredays);

        // //1분으로 쿠키체크
        // var date = new Date();
        // date.setTime(date.getTime() + 1 * 60 * 1000);

        document.cookie =
          name +
          "=" +
          escape(value) +
          "; path=/; expires=" +
          todayDate.toGMTString() +
          ";";
      },
    },
  };
</script>

<style leng="css" scoped>
  section {
    height: unset !important;
  }
  .mo {
    display: none;
  }
  /* modal */
  .modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
    transition: all 0.3s ease;
  }
  .modal_con {
    position: fixed;
    background-color: #fff;
    border-radius: 30px;
    max-width: 800px;
    width: calc(100% - 32px);
    left: 50%;
    top: 50%;
    z-index: 99;
    padding: 40px;
    box-sizing: border-box;
    transform: translate(-50%, -50%);
  }
  .modal_con button {
    height: 52px;
    width: 160px;
    background-color: #3943ff;
    border-radius: 26px;
    color: white;
    font-size: 16px;
    font-weight: bold;
  }
  .modal_bg {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 88;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.3);
  }
  .modal_header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  }
  .modal_header h2 {
    font-size: 20px;
    font-weight: bold;
    color: #111;
    margin-bottom: 0;
  }
  .modal_header .modal_close_btn {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  .modal_header .modal_text {
    height: 475px;
    width: 100%;
    overflow: auto;
    background-color: pink;
  }

  .modal label {
    font-size: 14px;
    font-weight: bold;
    columns: #111;
    display: block;
    margin-bottom: 8px;
  }
  .modal textarea,
  .modal select {
    border: solid 1px #e4e9f0;
    border-radius: 28px;
    padding: 16px 20px;
    width: 100%;
    margin-bottom: 16px;
    font-size: 14px;
    position: relative;
    resize: none;
  }
  .modal select {
    background-image: url(@/assets/slide_btn.svg);
    background-repeat: no-repeat;
    background-position: calc(100% - 20px) center;
  }

  .modal textarea::placeholder {
    color: #d6dce2;
  }

  #floating {
    /* width: 446px; */
    position: fixed;
    right: 40px;
    bottom: 40px;
    z-index: 99;
    height: 68px;
    overflow: hidden;
  }
  #floating button {
    background-color: #3943ff;
    width: 68px;
    height: 68px;
    border-radius: 50%;
    position: fixed;
    right: 40px;
    bottom: 40px;
    box-shadow: 0px 0px 24px rgba(0, 0, 17, 0.15);
  }
  #floating article {
    position: fixed;
    background-color: #fff;
    box-shadow: 0px 0px 24px rgba(0, 19, 190, 0.05);
    border-radius: 7px;
    border: 1px solid #f1f4f8;
    width: 403px;
    right: 120px;
    height: 52px;
    bottom: 47px;
    /* overflow: hidden; */
    box-sizing: border-box;

    /* animation: floating 3s ease-in-out 0.3s; */
  }
  #floating article p {
    line-height: 52px;
    width: 366px;
    line-height: 52px;
    position: absolute;
    text-align: center;
    margin-bottom: 0 !important;
    color: #111;
    font-size: 14px;
    letter-spacing: -0.35px;
  }
  #floating button > a {
    width: 100%;
    height: 100%;
    display: block;
    box-sizing: border-box;
  }
  #floating button img {
    display: block;
    margin: auto;
    padding-top: 27%;
    /* width: 30px;
    height: 30px; */
  }
  #floating article img {
    position: absolute;
    right: 20px;
    top: 19px;
    width: 15px;
    height: 15px;
  }
  @keyframes floating {
    0% {
      width: 0px;
      border: none;
      opacity: 0;
    }
    3% {
      border: solid 1px #f1f4f8;
      border-color: #f1f4f8;
      opacity: 1;
    }
    45% {
      width: 366px;
    }
    65% {
      width: 366px;
    }
    70% {
      width: 380px;
    }
    95% {
      border: solid 1px #f1f4f8;
      border-color: #f1f4f8;
      opacity: 1;
    }
    100% {
      width: 0px;
      border: none;
      opacity: 0;
    }
  }

  @media screen and (max-width: 1024px) {
    .mo {
      display: block;
    }
    .modal_con {
      padding: 24px 20px;
      border-radius: 20px;
    }
    .modal_con button {
      height: 42px;
      line-height: 42px;
    }
    .modal_header h2 {
      font-size: 18px;
    }
    .modal textarea,
    .modal select {
      padding: 11px 20px;
    }
    /*  */
    #floating {
      right: 16px;
      height: 56px;
    }
    #floating button {
      width: 56px;
      height: 56px;
      bottom: 18px;
      right: 18px;
    }
    #floating article {
      right: 18px;
      bottom: 82px;
      animation: none;
      width: 241px;
      height: 74px;
      border: solid 1px #f1f4f8;
      padding: 16px 20px !important;
    }
    #floating article img {
      position: absolute;
      right: 16px;
      top: 16px;
      width: 14px;
      height: 14px;
    }
    #floating article p {
      line-height: 20px;
      text-align: left;
      width: unset;
    }
    #floating button img {
      display: block;
      margin: auto;
      padding-top: 25%;
      width: 24px;
    }
  }
</style>
