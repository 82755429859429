<template>
  <header class="navHeader" :class="{ active }" @click="setActive">
    <div class="container-header" style="padding-left: 30px">
      <div class="header-title">
        <h1>
          <a v-if="type === 'demo'" href="/">
            <img src="@/assets/T-search_Logo-typo.svg" alt="" />
          </a>
          <router-link v-else to="/keyword/analysis">
            <img src="@/assets/T-search_Logo-typo.svg" alt="" />
          </router-link>
        </h1>
      </div>

      <div class="container-nav">
        <nav class="nav_wrap">
          <p>검색</p>

          <ul>
            <li
              :class="{ active: path === item.name ? true : false }"
              v-for="(item, index) in routeItems[0]"
              :key="index"
            >
              <div v-if="type === 'demo'" @click="setMsg">
                <span></span>{{ item.text }}
              </div>
              <router-link v-else :to="item.path">
                <span></span>{{ item.text }}
              </router-link>
            </li>
          </ul>

          <!-- 1011 추가 -->
          <p class="gold_chip">뉴스</p>

          <ul class="news">
            <li
              :class="{ active: path === item.name ? true : false }"
              v-for="(item, index) in routeItems[3]"
              :key="index"
            >
              <div v-if="type === 'demo'" @click="setMsg">
                <span></span>{{ item.text }}
              </div>
              <router-link v-else :to="item.path">
                <span></span>{{ item.text }}
              </router-link>
            </li>
          </ul>
        </nav>
      </div>

      <!-- 모바일전용 -->
      <div class="header_gnb-wrap mobile">
        <ul class="header_gnb-text">
          <li v-for="(item, index) in routeItems[1]" :key="index">
            <div v-if="type === 'demo'" @click="setMsg">{{ item.text }}</div>
            <router-link v-else :to="item.path">
              {{ item.text }}
            </router-link>
          </li>
        </ul>

        <ul class="header_gnb-icon">
          <li v-for="(item, index) in routeItems[2]" :key="index">
            <div v-if="type === 'demo'" @click="setMsg">
              <img :src="item.icon" :alt="item.text" />
            </div>
            <router-link v-else :to="item.path">
              <img :src="item.icon" :alt="item.text" />
            </router-link>
          </li>
          <li>
            <div href="" @click="logout">
              <img
                src="@/assets/img/common/icon_gnb-logout.svg"
                alt="로그아웃"
              />
            </div>
          </li>
        </ul>
      </div>
    </div>
  </header>
</template>
<script>
  import { mapActions, mapGetters } from "vuex";
  import AppUtility from "@/utility/app";
  export default {
    name: "NavWrap",
    props: ["type"],
    data() {
      return {
        path: "",
        routeItems: [
          [
            {
              text: "키워드분석",
              path: "/keyword/analysis",
              name: "HomeView",
            },
            {
              text: "키워드관리",
              path: "/keyword/manager",
              name: "KeywordManage",
            },
            {
              text: "그룹관리",
              path: "/group/manager",
              name: "GroupManage",
            },
          ],
          [
            {
              text: "멤버쉽안내",
              path: "/memeber/info",
              name: "MemberInfo",
            },
            // {
            //   text: "초대하기",
            //   path: "/memeber/invite",
            //   name: "HomeView",
            // },
          ],
          [
            // {
            //   text: "홈",
            //   path: "/",
            //   name: "HomeView",
            //   icon: require("@/assets/img/common/icon_gnb-home.svg"),
            // },
            {
              text: "마이페이지",
              path: "/mypage",
              name: "MypageView",
              icon: require("@/assets/img/common/icon_gnb-mypage.svg"),
            },
          ],
          [
            {
              text: "피드",
              path: "/news/feeds",
              name: "FeedsView",
            },
            {
              text: "보관함",
              path: "/news/save",
              name: "SaveView",
            },
          ],
        ],
        query: {},
      };
    },
    computed: {
      ...mapGetters({ active: "menu/menuActive" }),
    },

    watch: {
      $route(to) {
        this.path = to.name;
      },
    },
    mounted() {
      this.path = this.$router.history.current.name;
    },
    methods: {
      ...mapActions({
        logoutAction: "account/logout",
      }),
      setActive() {
        this.$store.commit("menu/toggleMenu", false);
      },
      setMsg() {
        this.$emit("setMsg");
      },
      async logout() {
        let active = await AppUtility("logout");
        if (!active) {
          if (this.type === "demo") {
            location.href = "/m";
          } else {
            this.logoutAction().then(() => {
              this.$router
                .replace({
                  path: "/login",
                })
                .catch(() => {});
            });
          }
        }
      },
    },
  };
</script>
<style lang="scss" scope>
  .v-application ol,
  .v-application ul {
    padding-left: 0px !important;
  }
  p.gold_chip {
    position: relative;
    left: 0;
    top: 0;
  }
  p.gold_chip::after {
    content: "Gold";
    width: 41px;
    height: 20px;
    position: absolute;
    font-size: 10px;
    color: white;
    left: 33px;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    line-height: 19px;

    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-image: url(@/assets/img/gold-label.svg);
  }
</style>
